import {axios, axiosPrivate, BASE_URL} from '../api/axios';

import authHeader from "./auth-header";

const CUSTOMER_REFERENCES = "/shop/customers/ID/price-offers"
const CUSTOMER_ORDERS = "/shop/orders"
const CUSTOMER_ADDRESS = "/shop/addresses"
const CUSTOMER_INFOS = "/shop/customers/ID"
const CUSTOMER_PSWD = "/shop/customers/ID/password"

const getData = async (postUrl, customerId) => {
    postUrl = postUrl.replace('ID', customerId)
    return axiosPrivate.get(BASE_URL + postUrl, {headers: authHeader()})
        .then(
            (response) => {
                return response.data;
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                console.log("---> ", resMessage);
                throw error
            }
        )
}


export {
    getData,
    CUSTOMER_REFERENCES,
    CUSTOMER_ORDERS,
    CUSTOMER_ADDRESS,
    CUSTOMER_INFOS,
    CUSTOMER_PSWD
};
