import React from "react";
import {Box} from "@mui/material";
import './video-header.scss';

function VideoHeader(props) {

    const frontEndUrl = process.env.REACT_APP_FRONTEND_URL
    const videoUrl = `${frontEndUrl}/media/tutorial.mp4`

    return (
        <>
            <Box className="video-container">
                <video controls loop={true} autoPlay={true} muted={true}>
                    <source src={videoUrl} type="video/mp4" />
                    {/*Votre navigateur ne supporte pas la lecture de vidéo.*/}
                </video>
            </Box>
        </>
    )
}

export default VideoHeader;