import React, {useContext, useEffect, useState} from "react";
import Shipping from "./shipping/Shipping";
import {Box} from "@mui/material";
import Cart from "./cart/Cart";
import Payment from "./payment/Payment";
import {Navigate} from "react-router";
import useCartProcess from "../../hooks/useCartProcess";
import CalculatorRigidContext from "../../context/calculatorRigidProvider";
import BackendConnection from "../backend-connection/backend-connection";
import {getStorageValue} from "../../tools/cookie-handler";
import CalculatorAluminumContext from "../../context/calculatorAluminumProvider";
import CalculatorHdiContext from "../../context/calculatorHdiProvider";
import CalculatorFlexContext from "../../context/calculatorFlexProvider";
import {useNavigate} from "react-router-dom";
import {logInConsole} from "../../tools/debug";


function ShoppingCart() {

    const localStorageCart = getStorageValue('cart');
    const checkoutCalculator = localStorageCart?.checkout.calculator;
    const repeatOrderMode = localStorageCart?.repeatOrderMode;
    const calculatorContexts = {
        "aluminum": CalculatorAluminumContext,
        "hdi": CalculatorHdiContext,
        "flex": CalculatorFlexContext,
    };
    const calculatorUsed = calculatorContexts[checkoutCalculator] || CalculatorRigidContext;

    //BACK HOME IF NO CART IN LOCALSTORAGE
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorageCart === null) {
            logInConsole('No local storage cart, go home', 'orange');
            navigate('/', {replace: true});
        }
    }, [])

    const { connectToBO, userGerber, setUserGerber, userSpecFile, setUserSpecFile, country } = useContext(calculatorUsed);


    const cartProcess = useCartProcess();
    const [addressesChooseByCustomer, setAddressesChooseByCustomer] = useState();
    const [paymentChooseByCustomer, setPaymentChooseByCustomer] = useState();
    const [isGerberUploaded, setIsGerberUploaded] = useState(false);
    const [invoiceTotalPaypal, setInvoiceTotalPaypal] = useState(0)

    const cancelCart = async () => {
        setUserGerber([]);
        await cartProcess.cancelCart();
    }


    useEffect(() => {
        // cartProcess.setAddresses(addressesChooseByCustomer)
        cartProcess.handleAddressesChooseByCustomer(addressesChooseByCustomer)
    }, [addressesChooseByCustomer])
    useEffect(() => {
        cartProcess.setPayment(paymentChooseByCustomer)
    }, [paymentChooseByCustomer])


    return (
        <Box className="shopping-wrapper">
            {cartProcess.checkoutState === "completed" && <Navigate to="/checkout" replace={true}/>}

            {/*PROGRESS BAR CONNECT TO BO*/}
            {connectToBO ? ( <BackendConnection/> ) : ""}

            {/*TODO make a nice popup*/}
            {/*{cartProcess.warnUser ? <div style={{"color":'red'}}> POPUP WARNING </div>: ''}*/}


            <Shipping addresses={cartProcess.customerAddresses}
                      addressesChooseByCustomer={setAddressesChooseByCustomer}
                      country={country}/>

            <Box className="shopping-data-card cart-card">
                <Cart
                    items={cartProcess.cartItems}
                    shippingCost={cartProcess.shippingCost}
                    orderPricing={cartProcess.orderPricing}
                    setInvoiceTotalPaypal={setInvoiceTotalPaypal}
                    handleVoucherChooseByCustomer={cartProcess.handleVoucherChooseByCustomer}
                    voucherList={cartProcess.voucherList}
                    orderPromotionTotal={cartProcess.orderPromotionTotal}
                    isWrongPromoCode={cartProcess.isWrongPromoCode}
                    setIsWrongPromoCode={cartProcess.setIsWrongPromoCode}
                    isCartReadyToProcessPayment={cartProcess.isCartReadyToProcessPayment}
                    gerber={{isGerberUploaded, setIsGerberUploaded, userGerber, setUserGerber, userSpecFile, setUserSpecFile}}
                    repeatOrderMode={repeatOrderMode}
                    country={country}
                />
                <Payment
                    invoiceTotalPaypal={invoiceTotalPaypal}
                    proceedToCheckoutCallBack={cartProcess.proceedToCheckout}
                    paymentChooseByCustomer={setPaymentChooseByCustomer}
                    paymentMethods={cartProcess.paymentMethods}
                    cancelCart={cancelCart}
                    isCartReadyToProcessPayment={cartProcess.isCartReadyToProcessPayment}
                    isGerberUploaded={isGerberUploaded}
                    repeatOrderMode={repeatOrderMode}
                    uploadCustomerDocuments={cartProcess.uploadCustomerDocuments}
                />
            </Box>
        </Box>
    )
}

export default ShoppingCart
