import {logInConsole} from "./debug";
import {matchIsValidTel} from "mui-tel-input";

//*** prevent JS hack ***
const USER_REGEX = /^[a-zA-Z\d- .]{2,16}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-+<>_.])[A-Za-z\d!@#$%^&*()\-+<>_.]{12,}$/;


const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const ZIP_CODE_REGEX = /^[a-zA-Z0-9\s\-]+$/;
const SIRET_REGEX = /^\d{14}$/;

/**
 * Use regex to check a string with ICAPE standards
 * @param {string} stringToTest
 * @param {"name"|"password"|"email"|"zipCode"|"siret"} fieldType
 * @returns {boolean}
 */
const regex = (stringToTest, fieldType) => {
    if (fieldType === 'name') {
        logInConsole('Regex test : name type', 'deepskyblue')
        return USER_REGEX.test(stringToTest);
    }
    if (fieldType === 'password') {
        logInConsole('Regex test : password type', 'deepskyblue');
        return PWD_REGEX.test(stringToTest);
    }
    if (fieldType === 'email') {
        logInConsole('Regex test : mail type', 'deepskyblue');
        return EMAIL_REGEX.test(stringToTest);
    }
    if (fieldType === 'zipCode') {
        logInConsole('Regex test : zip code type', 'deepskyblue');
        return ZIP_CODE_REGEX.test(stringToTest);
    }
    if (fieldType === 'siret') {
        logInConsole('Regex test : siret type', 'deepskyblue');
        return SIRET_REGEX.test(stringToTest);
    }
    logInConsole('Error, is good regex type ? ', 'red');
}

export {
    regex
}
