import {Box, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";

function CommentField(props) {
    const {t} = useTranslation();

    const isActive = props.isActive
    const {comment, setComment, isLockFields} = props.fields


    return(
        <>
            <Box className="technical-data-card">
                <Typography variant={'h3'}>
                    {t("common.comments")}
                </Typography>

                {
                    isActive ?
                        <TextField
                            id="comments"
                            label={t("common.comments")}
                            multiline
                            rows={4}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            disabled={isLockFields}
                            sx={{width: '100%'}}
                        />
                        :
                        <Box component={"span"}>
                            Comments section is currently unavailable, please contact support if necessary:
                            quickturn@icape-group.com
                        </Box>
                }


            </Box>
        </>
    )
}

export default CommentField