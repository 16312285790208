import React, {useContext, useEffect, useMemo, useState} from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import ProjectPanel from "../components/project-panel/project-panel";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Snackbar
} from "@mui/material";
import {CUSTOMER_ORDERS, CUSTOMER_REFERENCES, getData} from "../../../services/customer.service";
import useAuth from "../../../hooks/useAuth";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Navigate} from "react-router";
import useJayda from "../../../hooks/useJayda";
import {ButtonIcape, ButtonIcapeOutlined} from "../../button/button-icape";
import '../calculators.scss';
import './calculator-rigid.scss';
import CalculatorRigidContext from "../../../context/calculatorRigidProvider";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../../../tools/debug";
import {getAreaSize, mmToInch} from "../../../tools/unit.utils";
import debounce from "lodash.debounce";
import UserContext from "../../../context/UserProvider";
import {useTranslation} from "react-i18next";
import BackendConnection from "../../backend-connection/backend-connection";
import LockIcon from "@mui/icons-material/Lock";
import {createFilterOptions} from "@mui/material/Autocomplete";
import {generateNewAutoReference} from "../../../tools/auto-naming";
import {snackbarTransition} from "../../../tools/snackbarTransition";
import CalculatorStepper from "../components/calculator-stepper";
import StepNavigation from "../components/step-navigation";
import CalculatorToolbar from "../components/calculator-toolbar";
import {maxPcbCalculator} from "../../../tools/quantity-checks";
import {
    handleDevisorViewEvent,
    handleDownloadQuoteEvent,
    handleFirstInteractionEvent,
    handleOrderNowEvent,
    handleVirtualPageView
} from "../../../tools/google-tag-manager-events";
import {ErrorHandling} from "../../../tools/error-handling";

function CalculatorRigid() {
    const {t} = useTranslation();

    const steps = [`${t("calculators.step1.name")}`, `${t("calculators.step2.name")}`, `${t("calculators.step3.name")}`];
    /**
     * Calculator name / type
     * @type {string}
     */
    const calculator = 'rigid';

    const axiosPrivate = useAxiosPrivate();
    const {sendJob} = useJayda(calculator);
    const {auth} = useAuth();
    const {company} = useContext(UserContext);
    const [openLoadPcbReferencePopup, setOpenLoadPcbReferencePopup] = useState(false);
    const [queryParameters] = useSearchParams();
    let _persist = false;

    const navigate = useNavigate();

    const {
        idOrder, setIdOrder,
        priceOfferId,
        priceMatrix, setPriceMatrix,
        repeatOrderMode, setRepeatOrderMode,
        repeatOrderCreationAt,
        activeStep, setActiveStep,
        completed, setCompleted,
        partNumber, setPartNumber,
        quantity, setQuantity,
        unitFormat, setUnitFormat,
        isLockFields, setIsLockFields,
        setIsJaydaPcbQuotable,
        userGerber, setUserGerber,
        jobParams,
        pcbImagesUrls,
        analyzeInProgress,
        progress,
        isErrorConnectToJayda, setIsErrorConnectToJayda,
        pcbDeliveryFormat,
        loadCurrency,
        setIsStepOneToFinalize,
        pcbLength, setPcbLength,
        pcbWidth, setPcbWidth,
        pcbLengthIn, setPcbLengthIn,
        pcbWidthIn, setPcbWidthIn,
        panelLength,
        panelWidth,
        panelLengthIn,
        panelWidthIn,
        pcbPerPanel, setPcbPerPanel,
        panelQuantity, setPanelQuantity,
        lockCustomPanelizationFields,
        customPanelizationSettings,
        solderMaskId, setSolderMaskId,
        technologyId, setTechnologyId,
        smallestDrillingHoleSizeId, setSmallestDrillingHoleSizeId,
        legendId, setLegendId,
        pluggingViaId, setPluggingViaId,
        carbonInkId,
        maskOptionId,
        tracksWidthAndSpaceId, setTracksWidthAndSpaceId,
        xoutId,
        rawMaterialId,
        finishedInnerCuId, setFinishedInnerCuId,
        boardThicknessId,
        basedOuterCuId, setBasedOuterCuId,
        solderMaskColorId, setSolderMaskColorId,
        finishedOuterCuId, setFinishedOuterCuId,
        finishingKey,
        resinFillingId, setResinFillingId,
        extraPrepregsId,
        legendColorId, setLegendColorId,
        pressFitHoleId,
        ipc3Id,
        ulCountryId,
        vCutId,
        ionicContaminationId,
        cti600Id,
        impedanceControlId,
        shippingMethodId,
        country,
        stencilTypeId,
        stencilEdgeId,
        stencilThicknessId,
        stencilFrameId,
        requestedDate,
        minDeliveryDate, setMinDeliveryDate,
        daysToDelivery, setDaysToDelivery,
        productionTime, setProductionTime,
        comment,
        shippingCost_PP, setShippingCost,
        unitPrice_PP, setUnitPrice_PP,
        stencil_PP, setStencil_PP,
        total_PP, setTotal_PP,
        initialTotalPricePP,
        incoterm_PP, setIncoterm_PP,
        voucher, setVoucher,
        setWeight,
        tariff, setTariff,
        batchSize, setBatchSize,
        connectToBO, setConnectToBO,
        errorBackendConnection, setErrorBackendConnection,
        customerReferences, setCustomerReferences,
        customerReferenceChoose, setCustomerReferenceChoose,
        repeatOrdersSelectedAvailable, setRepeatOrdersSelectedAvailable,
        isAvailableOrderSelected, setIsAvailableOrderSelected,
        productVariant, setProductVariant,
        orderToken,
        loadCustomerReferenceRigid, resetAllRigid
    } = useContext(CalculatorRigidContext);

    const {activeCurrency, setActiveCurrency, orderCurrency, setOrderCurrency, companyId} = useContext(UserContext);
    const {currencyCode} = useContext(UserContext);
    const [isReadyToBeSent, setIsReadyToBeSent] = useState(false);

    const [isStencilRoUpdated, setIsStencilRoUpdated] = useState(false);

    // GTM STATES
    const [firstInteraction, setFirstInteraction] = useState(true);
    const [onLoad, setOnLoad] = useState(true);

    // GTM EVENT : devisor_view
    const gtmCalculatorStepEvent = () => {
        handleDevisorViewEvent(calculator, pcbDeliveryFormat, activeStep);
    }

    // GTM VIEW : VirtualPageview
    useEffect(() => {
        handleVirtualPageView("calculator", activeStep);
    }, [activeStep]);


    // STATES FOR REPEAT ORDER
    const [openROAddReferenceDialog, setOpenROAddReferenceDialog] = React.useState(false);
    const [rOAddReferenceDialogValue, setROAddReferenceDialogValue] = React.useState({title: '', isNewReference: true});
    const [openCustomerPriceOffersList, setOpenCustomerPriceOffersList] = React.useState(false);
    let loadingForCustomerPriceOffers = openCustomerPriceOffersList && customerReferences.length === 0;
    const [loadingForCustomerPriceOffers2, setLoadingForCustomerPriceOffers2] = useState(false);
    const [isCustomerReferenceFieldEmpty, setIsCustomerReferenceFieldEmpty] = React.useState(false);
    const [addReferenceDialogCallingButton, setAddReferenceDialogCallingButton] = useState(null);
    const [isPcbRefAlreadyExisting, setIsPcbRefAlreadyExisting] = useState(false);


    // STATE FOR PANELIZATION
    const [showPanelization, setShowPanelization] = useState(false);


    // HANDLE COOKIE STORAGE AND SEND PRICE OFFER
    useEffect(() => {
        if (isReadyToBeSent) {
            localStorage.setItem(calculator, JSON.stringify({
                quantity,
                unitFormat,
                incoterm: incoterm_PP,
                pcb: {
                    pcbLength,
                    pcbWidth,
                    pcbLengthIn,
                    pcbWidthIn,
                    solderMaskId,
                    technologyId,
                    smallestDrillingHoleSizeId,
                    tracksWidthAndSpaceId,
                    legendId,
                    xoutId,
                    extraPrepregsId,
                    finishedInnerCuId,
                    basedOuterCuId,
                    finishedOuterCuId,
                    ipc3Id,
                    pluggingViaId,
                    resinFillingId,
                    rawMaterialId,
                    boardThicknessId,
                    solderMaskColorId,
                    legendColorId,
                    finishingKey,
                    ulCountryId,
                    vCutId,
                    ionicContaminationId,
                    cti600Id,
                    impedanceControlId,
                    pressFitHoleId,
                    carbonInkId,
                    maskOptionId,
                },
                panel: {
                    panelQuantity,
                    pcbPerPanel,
                    mm: {
                        panelLength,
                        panelWidth
                    },
                    in: {
                        panelLengthIn,
                        panelWidthIn,
                    }
                },
                stencil: {
                    stencilTypeId,
                    stencilEdgeId,
                    stencilThicknessId,
                    stencilFrameId,
                },
                shipping: {
                    shippingMethodId,
                    country,
                    daysToDelivery,
                    productionTime,
                    requestedDate,
                    minDeliveryDate,
                },
                partNumber,
                currencyCode,
                loadCurrency,
                repeatOrder: {
                    repeatOrderMode,
                    repeatOrderCreationAt,
                    prices: {
                        unit: unitPrice_PP,
                        shipping: shippingCost_PP,
                        stencilUnitPrice: stencil_PP,
                        initialTotal: initialTotalPricePP,
                        total: total_PP
                    },
                    productVariant
                },
                isLockFields,
                lockCustomPanelizationFields,
                customPanelizationSettings,
                pcbDeliveryFormat,
                userGerber,
                comment
            }));

            if(Object.keys(country).length === 0){
                logInConsole("No country", 'red');
                setIsReadyToBeSent(false);
                return
            }

            if(isLockFields){
                sendPriceOffer()
            } else {
                checkQuantity() && sendPriceOffer()
            }

            setIsReadyToBeSent(false);
        }
    }, [isReadyToBeSent]);


    //debounce useEffect to avoid backend to be spammed
    const readyToSaveAndSend = () => {
        setIsReadyToBeSent(true);
    }
    const debouncePriceOffer = useMemo(() => debounce(readyToSaveAndSend, 800), []);
    // DEBOUNCE LAUNCHER
    useEffect(() => {
        if (onLoad) {
            setOnLoad(false);
        } else {
            if (firstInteraction) {
                // GTM EVENT : first_interaction
                handleFirstInteractionEvent(calculator, pcbDeliveryFormat);
                setFirstInteraction(false);
            }
        }
        debouncePriceOffer();
    }, [
        quantity,
        panelQuantity,
        unitFormat,
        currencyCode,
        pcbDeliveryFormat,
        pcbLength,
        pcbWidth,
        panelLength,
        panelWidth,
        pcbLengthIn,
        pcbWidthIn,
        panelLengthIn,
        panelWidthIn,
        pcbPerPanel,
        solderMaskId,
        technologyId,
        smallestDrillingHoleSizeId,
        tracksWidthAndSpaceId,
        legendId,
        xoutId,
        extraPrepregsId,
        finishedInnerCuId,
        basedOuterCuId,
        finishedOuterCuId,
        ipc3Id,
        pluggingViaId,
        resinFillingId,
        rawMaterialId,
        boardThicknessId,
        solderMaskColorId,
        legendColorId,
        finishingKey,
        ulCountryId,
        vCutId,
        ionicContaminationId,
        cti600Id,
        impedanceControlId,
        pressFitHoleId,
        carbonInkId,
        maskOptionId,
        shippingMethodId,
        country,
        stencilTypeId,
        stencilEdgeId,
        stencilThicknessId,
        stencilFrameId,
        stencil_PP,
        requestedDate,
        comment]);
    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
        return () => {
            debouncePriceOffer.cancel();
        }
    }, []);



    // UTILS
    const handleNewProject = () => {
        // On le fait déjà dans unlock fields
        // if(repeatOrderMode.enabled) {
        //     logInConsole('Leaving Rigid repeat order mode', 'gold');
        //     if (JSON.parse(localStorage.getItem('cart'))?.checkout.calculator === calculator) {
        //         localStorage.removeItem('cart');
        //     }
        // }
        handleUnlockFields();
        resetAllRigid();
        setIsCustomerReferenceFieldEmpty(false);
    }
    const handleUnlockFields = () => {
        setCustomerReferenceChoose({title: '', isNewReference: false, isOrdered: false});
        setPartNumber('');
        setIsLockFields(false);

        if(repeatOrderMode.enabled) {
            logInConsole('Leaving Rigid repeat order mode', 'gold');
            if (JSON.parse(localStorage.getItem('cart'))?.checkout.calculator === calculator) {
                localStorage.removeItem('cart');
            }
            setProductVariant([]);
            setRepeatOrderMode({...repeatOrderMode, enabled: false, lockPrice: false})
        }
    }


    // HANDLE REFERENCE
    const handleValidLoadReference = () => {
        setOpenLoadPcbReferencePopup(false);
        let referenceToLoad = customerReferences.find(obj => {
            return obj.PCB.partNumber === customerReferenceChoose.title;
        });

        // setConnectToBO(true);
        // axiosPrivate.get('/shop/' + referenceToLoad.id + '/price-offer-to-order')
        //     .then(
        //         (response) => {
        //             logInConsole(`PCB details sent by backend :`, 'chocolate');
        //             logInConsoleObject(response.data);
        //             loadCustomerReferenceRigid(response.data, false, false);
        //         },
        //         (error) => {
        //             const resMessage = (error.response && error.response.data && error.response.data.message) ||
        //                 error.message || error.toString();
        //             setErrorBackendConnection(true);
        //             ErrorHandling(calculator, resMessage);
        //         }
        //     ).finally(
        //     () => {
        //         setConnectToBO(false);
        //     }
        // )

        setConnectToBO(true);
        if (referenceToLoad.isOrdered) {
            axiosPrivate.get(CUSTOMER_ORDERS + '/' + idOrder + '/repeatOrderInfos')
                .then(
                    (response) => {
                        logInConsole(`PCB details sent by backend :`, 'chocolate');
                        logInConsoleObject(response.data);
                        loadCustomerReferenceRigid(response.data, true, false);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        setErrorBackendConnection(true);
                    }
                ).finally(
                () => {
                    setConnectToBO(false);
                }
            )

        }
        else {
            axiosPrivate.get('/shop/' + referenceToLoad.id + '/price-offer-to-order')
                .then(
                    (response) => {
                        logInConsole(`PCB details sent by backend :`, 'chocolate');
                        logInConsoleObject(response.data);
                        loadCustomerReferenceRigid(response.data, false, false);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        setErrorBackendConnection(true);
                    }
                ).finally(
                () => {
                    setConnectToBO(false);
                }
            )
        }
    };
    const handleCancelLoadReference = () => {
        setCustomerReferenceChoose({title: '', newReference: false, isOrdered: false});
        setOpenLoadPcbReferencePopup(false);
        setIsLockFields(false);
        setIsCustomerReferenceFieldEmpty(true);
        setRepeatOrdersSelectedAvailable([]);
        setIsAvailableOrderSelected(false);
    };
    const handleAddNewReference = (e) => {

        for (let i = 0; i < customerReferences.length; i++) {
            if (customerReferences[i].PCB.partNumber.toLowerCase().trim() === rOAddReferenceDialogValue.title.toLowerCase().trim()) {
                setIsPcbRefAlreadyExisting(true);
                return
            }
        }

        logInConsole("[handle] Add new reference : ");
        logInConsoleObject(rOAddReferenceDialogValue);
        setCustomerReferenceChoose(rOAddReferenceDialogValue);
        setPartNumber(rOAddReferenceDialogValue.title);
        setProductVariant([]);
        setOpenROAddReferenceDialog(false);
        setIsCustomerReferenceFieldEmpty(false);
    };




    // CHECKOUT
    let checkoutChoice = '';
    const handleDownloadQuote = (e) => {
        handleAddNewReference();
        checkoutProcess('downloadQuote');
    }
    const handleOrderNow = (e) => {
        handleAddNewReference()
        checkoutProcess('orderNow');
    }
    const [checkout, setCheckout] = useState(false);


    const sendPriceOffer = () => {
        if (!checkIfAllFieldsAreFilled() || analyzeInProgress === 'inProgress' || repeatOrderMode.lockPrice || isNaN(quantity)){
            return
        }
        let config = {headers: {"Content-Type": "application/json"}};
        let data = {
            "quantity": parseInt(quantity),
            unitFormat,
            "customerId": parseInt(auth.customerId),
            currencyCode: repeatOrderMode.lockPrice ? loadCurrency.code : currencyCode,
            "deliveryFormat" : pcbDeliveryFormat,
            persist: _persist,
            "countryId": country.id,
            "pcbLength": parseFloat(pcbLength),
            "pcbWidth": parseFloat(pcbWidth),
            "pcbLengthIn": parseFloat(pcbLengthIn),
            "pcbWidthIn": parseFloat(pcbWidthIn),
            "pcbPerPanel": pcbDeliveryFormat === 'single' ? 1 : parseInt(pcbPerPanel),
            customPanelizationSettings,
            "panel":{
                "panelQuantity": pcbDeliveryFormat === 'single' ? null : panelQuantity,
                "mm":{
                    "panelLength" : pcbDeliveryFormat === 'single' ? null : panelLength,
                    "panelWidth" : pcbDeliveryFormat === 'single' ? null : panelWidth
                },

                "in":{
                    "panelLengthIn" : pcbDeliveryFormat === 'single' ? null : panelLengthIn,
                    "panelWidthIn" : pcbDeliveryFormat === 'single' ? null : panelWidthIn,
                }
            },
            partNumber,
            pcbImagesUrls,
            solderMaskId,
            technologyId,
            smallestDrillingHoleSizeId,
            tracksWidthAndSpaceId,
            legendId,
            xoutId,
            "countSinkHolesId": 1,
            pluggingViaId,
            finishedInnerCuId,
            finishedOuterCuId,
            basedOuterCuId,
            resinFillingId,
            extraPrepregsId,
            rawMaterialId,
            cti600Id,
            ipc3Id,
            pressFitHoleId,
            boardThicknessId,
            solderMaskColorId,
            legendColorId,
            finishingKey,
            ulCountryId,
            vCutId,
            ionicContaminationId,
            impedanceControlId,
            maskOptionId,
            carbonInkId,
            stencilTypeId,
            stencilEdgeId,
            stencilThicknessId,
            stencilFrameId,
            requestedDate,
            shippingMethodId,
            comment,
            "repeatOrder": repeatOrderMode.background,
        };

        setConnectToBO(true);
        axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/rigid-quotations`, data, config)
            .then(
                (response) => {
                    logInConsoleSpacer();
                    logInConsole(`Rigid quotation received :`, 'teal');
                    logInConsoleObject(response.data);

                    setConnectToBO(false);
                    setErrorBackendConnection(false);
                    let apiResponse = response.data;
                    setIncoterm_PP(apiResponse.incoterm.name);
                    setMinDeliveryDate(apiResponse.minDeliveryDate);
                    setDaysToDelivery(apiResponse.daysToDelivery);
                    setProductionTime(apiResponse.productionTime);
                    setPriceMatrix(apiResponse.priceMatrix);

                    //Cart cookie
                    setShippingCost(apiResponse.shippingCost);
                    setStencil_PP(apiResponse.stencilPrice);
                    setUnitPrice_PP(apiResponse.unitPrice);
                    setTotal_PP(apiResponse.fullPrice);
                    setVoucher(apiResponse.vouchers)
                    setWeight(apiResponse.weight);
                    setTariff(apiResponse.tariff);
                    setBatchSize(apiResponse.batchSize);

                    if (_persist) {
                        if (checkoutChoice === 'orderNow' && apiResponse.productVariant) {
                            logInConsole(`Order now with product variant:`, 'steelblue');
                            logInConsoleObject(apiResponse.productVariant);

                            setProductVariant(apiResponse.productVariant);
                            window.scrollTo(0, 0);

                            // GTM EVENT : order_now
                            handleOrderNowEvent(calculator, pcbDeliveryFormat);
                        }
                        if (checkoutChoice === 'downloadQuote' && apiResponse.priceOfferPdf !== null && apiResponse.priceOfferPdf !== "" && _persist) {

                            // GTM EVENT : download_my_quote
                            handleDownloadQuoteEvent(calculator, pcbDeliveryFormat);

                            window.open(process.env.REACT_APP_BACKEND_BASE_URL + '/' + apiResponse.priceOfferPdf);
                        }
                        if (!repeatOrderMode.enabled) {
                            checkoutChoice = '';
                        }
                        repeatOrderMode.enabled && repeatOrderToCart();
                    }
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsoleObject(resMessage);
                    setErrorBackendConnection(true);
                    ErrorHandling(calculator, resMessage);
                }
            ).finally(
            () => {
                setConnectToBO(false);
            }
        )
    };
    const checkoutProcess = (_checkoutChoice) => {
        if(errorBackendConnection || connectToBO)
        {
            //alert('TODO : Backend can not get back a valid response. How to show this behavior to user ?');
            return;
        }
        setAddReferenceDialogCallingButton(_checkoutChoice);
        //TODO C'est ici que ca doit casser quand il se passe rien au clic sur download my quote
        if (customerReferenceChoose.title === '' && rOAddReferenceDialogValue.title === '') {
            setIsCustomerReferenceFieldEmpty(true);
            setOpenROAddReferenceDialog(true);
            setROAddReferenceDialogValue({title: generateNewAutoReference(company), isNewReference: true});
            return;
        }
        setIsCustomerReferenceFieldEmpty(false);

        let newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);

        checkoutChoice = _checkoutChoice;

        setCheckout(true);
    }


    useEffect(async () => {
        if (checkout && partNumber !== '') {
            _persist = true;
            checkoutChoice = addReferenceDialogCallingButton;

            if (repeatOrderMode.lockPrice) {
                setOrderCurrency(loadCurrency.symbol);

                if(isStencilRoUpdated) {
                    await productVariantUpdateStencilModifiedLockedPrice();
                    console.log('test')
                    setIsStencilRoUpdated(false);
                }

                repeatOrderToCart();
            } else {
                setOrderCurrency(activeCurrency);
                sendPriceOffer();
            }

            setCheckout(false);
        }
    }, [checkout, partNumber]);


    //PCB LOAD FORM TEXT FIELD
    const filter = createFilterOptions();
    useEffect(() => {
        let active = true;
        if (!loadingForCustomerPriceOffers) {
            return undefined;
        }
        (async () => {
            setLoadingForCustomerPriceOffers2(true)
            await getData(CUSTOMER_REFERENCES, auth.customerId)
                .then(
                    (response) => {
                        if (active) {
                            logInConsoleObject(response);
                            let referencesFilterred = [];
                            for (let i = 0; i < response.length; i++) {
                                let isPartNumberExists = false;

                                //Filtre pour éviter les part number identiques
                                referencesFilterred.map(reference => {
                                    if (response[i].PCB.partNumber === reference.PCB.partNumber) {
                                        isPartNumberExists = true
                                    }
                                })

                                // filtre pour pousser que les rigid
                                response[i].type === 'Rigid' && !isPartNumberExists && referencesFilterred.push(response[i] );
                            }
                            setCustomerReferences(referencesFilterred);
                        }
                    },
                    (error) => {
                        logInConsoleObject('error when obtaining customer references');
                    }
                ).finally(
                    () => {
                        setLoadingForCustomerPriceOffers2(false);
                    })
        })();

        return () => {
            active = false;
        }

    }, [loadingForCustomerPriceOffers]);


    // LOAD QUOTE / REPEAT ORDER FUNCTIONS
    /**
     * Updating quantity / delivery date breaks the locked price to get a new price from the price matrix.
     */
    const updateRepeatOrder = () => {
        if (repeatOrderMode.lockPrice) {
            logInConsole('Updating repeat order, price unlocked', 'gold');

            // TODO vider ou garder le même product variant ? voir si le back est capable de faire cette gestion
            setProductVariant([]);
            localStorage.removeItem("cart");
            setRepeatOrderMode({...repeatOrderMode, "lockPrice": false})
        }
    }

    /**
     * Asking backend stencil info when repeat order mode is active.
     * Adds / deletes / updates stencil and stencil price
     * Updates total price offer price including or not the stencil
     */
    const handleStencilUpdateOnLockedPrice = async () => {
        if (!repeatOrderMode.lockPrice) {
            return
        }
        logInConsole("Price locked & stencil update", "orange")

        //SENDING STENCIL INFO TO BACKEND
        let config = {headers: {"Content-Type": "application/json"}};
        let dataStencilUpdatedOnLockedPrice = {
            calculator,
            currencyCode,
            mode: repeatOrderMode.background ? "repeatOrder" : "loadQuote",
            pcb: {
                width: pcbWidth,
                length: pcbLength
            },
            shipping: {
                countryCode : country?.code,
                shippingMethodId
            },
            stencil: {
                stencilTypeId,
                stencilEdgeId,
                stencilThicknessId,
                stencilFrameId,
            },
        };

        logInConsoleObject(dataStencilUpdatedOnLockedPrice);
        setIsStencilRoUpdated(true);

        setConnectToBO(true);
        await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/get-stencil-info`, dataStencilUpdatedOnLockedPrice, config)
            .then(
                (response) => {
                    logInConsole(`New stencil price received :`, 'teal');
                    logInConsoleObject(response.data.stencilPrice);
                    setStencil_PP(response.data.stencilPrice);
                    setTotal_PP(initialTotalPricePP + response.data.stencilPrice);
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsoleObject(resMessage);
                    setErrorBackendConnection(true);
                    ErrorHandling(calculator, resMessage);
                }
            ).finally(
            () => {
                setConnectToBO(false);
            }
        )

    };

    /**
     * Locked price and stencil modified.
     * Backend needs to update all data in order to gives proper product variants
     */
    const productVariantUpdateStencilModifiedLockedPrice = async () => {
        logInConsoleSpacer();
        logInConsole('Try to update stencil with locked price', 'darkmagenta', 'bold');

        const data = {
            stencil: {
                stencilTypeId,
                stencilEdgeId,
                stencilThicknessId,
                stencilFrameId,
                stencilPrice: stencil_PP
            },
        }

        if (repeatOrderMode.background) {
            const id = idOrder;

            setConnectToBO(true);
            await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/orders/${id}/repeat-order`, data)
                .then(
                    (response) => {
                        logInConsole(`Order - Stencil updated with locked price and new product variant received`, 'green');
                        logInConsoleObject(response.data.productVariant);
                        setProductVariant(response.data.productVariant);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        logInConsoleObject(resMessage);
                        setErrorBackendConnection(true);
                        ErrorHandling(calculator, resMessage);
                    }
                ).finally(
                    () => {
                        setConnectToBO(false);
                    }
                )
        }
        else {
            const id = priceOfferId;

            setConnectToBO(true);
            await axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/price-offers/${id}/update-stencil-before-order-action`, data)
                .then(
                    (response) => {
                        logInConsole(`Price offer - Stencil updated with locked price and new product variant received`, 'green');
                        logInConsoleObject(response.data.productVariant);
                        setProductVariant(response.data.productVariant);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        logInConsoleObject(resMessage);
                        setErrorBackendConnection(true);
                        ErrorHandling(calculator, resMessage);
                    }
                ).finally(
                    () => {
                        setConnectToBO(false);
                    }
                )
        }
    }

    /**
     * During repeat order mode, go to cart without sendprice offer
     */
    const repeatOrderToCart = () => {
        _persist = false;

        if (checkoutChoice === 'orderNow') {
            checkoutChoice = '';
            navigate('/cart', {replace: false});
        }
        if (checkoutChoice === 'downloadQuote') {
            alert("télécharger un ti PDF Rigid");
           // window.open(process.env.REACT_APP_BACKEND_BASE_URL + '/' + apiResponse.priceOfferPdf);
            checkoutChoice = '';
        }

    };



    // CHECKING STEP FIELDS
    const checkIfAllFieldsAreFilled = () => {
        return !(quantity === '' ||
            pcbWidth === '' ||
            pcbLength === '' ||
            solderMaskId === '' ||
            technologyId === '' || technologyId === '-1' ||
            smallestDrillingHoleSizeId === '' ||
            tracksWidthAndSpaceId === '' ||
            legendId === '' ||
            (pcbDeliveryFormat !== 'single' && (panelWidth === '' || panelLength === ''))
        );
    }
    const stepOneToBeFilled = () => {
        setActiveStep(0)
        setIsStepOneToFinalize(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }



    //MAX AREA CHECK
    const [isPcbAreaOversized, setIsPcbAreaOversized] = useState(false);
    const checkQuantity = () => {
        const areaSize = getAreaSize(pcbWidth, pcbLength, pcbWidthIn, pcbLengthIn, pcbPerPanel, quantity, unitFormat);

        if (pcbDeliveryFormat === 'single') {
            if (quantity <= 0 || quantity === "" || isNaN(quantity)) {
                setQuantity(1);
                setPcbPerPanel(1);
                return false
            }
            if (quantity > 100000) {
                setQuantity(99999);
                return false
            }
            if (quantity < pcbPerPanel) {
                setPcbPerPanel(quantity);
            }
            if ((unitFormat === 'mm' && areaSize >= 50) || (unitFormat === 'in' && areaSize >= 77500)) {
                setIsPcbAreaOversized(true);
                return false
            }
            setIsPcbAreaOversized(false);
            return true;
        }

        if (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom') {
            if (panelQuantity <= 0 || panelQuantity === "" || isNaN(panelQuantity)) {
                setPanelQuantity(1);
                return false
            }
            if (panelQuantity > 1000) {
                setPanelQuantity(999);
                return false
            }
            if (pcbPerPanel > maxPcbCalculator(pcbWidth, pcbLength, panelWidth, panelLength) || pcbPerPanel === 0) {
                setPcbPerPanel(maxPcbCalculator(pcbWidth, pcbLength, panelWidth, panelLength));
                return false
            }
            if ((unitFormat === 'mm' && areaSize >= 50) || (unitFormat === 'in' && areaSize >= 77500)) {
                setIsPcbAreaOversized(true);
                return false
            }
            setIsPcbAreaOversized(false);
            return true;
        }

    }


    // PANEL QUANTITY AUTOMATION
    useEffect(() => {
        if((panelQuantity !== null && panelQuantity !== '') && (pcbPerPanel !== null && pcbPerPanel !== '') && (pcbDeliveryFormat === 'classic' || pcbDeliveryFormat === 'custom')){
            setQuantity(Math.floor(panelQuantity * pcbPerPanel));
        }
    }, [panelQuantity, pcbPerPanel, pcbDeliveryFormat])


    // JAYDA
    const [isJaydaPopupOpen, setIsJaydaPopupOpen] = useState(false);
    useEffect(() => {
        if (analyzeInProgress === 'inProgress') {

        }
        if (analyzeInProgress === 'ended') {
            sendPriceOffer();
        }
    }, [analyzeInProgress]);
    const handleDropFile = async (file) => {
        handleNewProject();
        setUserGerber(file);
        await sendJob(file);
    };
    const jaydaPcbNotQuotable = (fieldsOutsidePerimeter) => {
        setIsJaydaPcbQuotable(false);
        setIsJaydaPopupOpen(true);
        logInConsole("Fields outside perimeter:", 'orange');
        logInConsoleObject( fieldsOutsidePerimeter);
    }
    useEffect(() => {
        if (jobParams === undefined || jobParams === null) {
            return
        }
        logInConsole(`jobParams`, 'lightblue');
        logInConsoleObject(jobParams);

        if (Object.keys(jobParams).length !== 0) {

            // SIZE & NULL CHECK
            if (jobParams?.pcbWidth !== null && jobParams?.pcbLength !== null) {
                switch (true) {
                    case jobParams?.pcbWidth < 10 || jobParams?.pcbWidth > 480 :
                        jaydaPcbNotQuotable("PCB Width");
                        break;
                    case jobParams?.pcbLength < 15 || jobParams?.pcbLength > 580 :
                        jaydaPcbNotQuotable("PCB Length");
                        break;
                    default:
                        setPcbWidth(jobParams?.pcbWidth);
                        setPcbLength(jobParams?.pcbLength);
                        setPcbWidthIn(mmToInch(jobParams?.pcbWidth));
                        setPcbLengthIn(mmToInch(jobParams?.pcbLength));
                }
            }

            // TECHNOLOGIE ID
            if (jobParams?.technologyId !== null && jobParams?.technologyId !== -1 && jobParams?.technologyId < 7){
                setTechnologyId(jobParams?.technologyId);
            } else {
                jaydaPcbNotQuotable("Technologie ID");
            }


            setSolderMaskId(jobParams?.solderMaskId);
            setSmallestDrillingHoleSizeId(jobParams?.smallestDrillingHoleSizeId);
            setTracksWidthAndSpaceId(jobParams?.tracksWidthSpaceId);
            setLegendId(jobParams?.legendId);
        }
    }, [jobParams]);


    /**
     * Allow the cart to use the right product variant
     * Once the product variant is received from the backend, create a local storage item readable and mandatory for the cart
     */
    useEffect(() => {
        if(productVariant === null || productVariant === undefined || productVariant === '' || Object.keys(productVariant).length === 0) {
            return
        }
        localStorage.setItem('cart', JSON.stringify({
            productVariant,
            orderToken,
            voucher,
            checkout: {calculator: calculator, method: ""},
            repeatOrderMode
        }));
    }, [productVariant]);


    // CART REDIRECTION IF RIGID CART ACTIVE
    useEffect(() => {
        const cartLocalStorage = JSON.parse(localStorage.getItem('cart'))
        if(cartLocalStorage?.productVariant.length > 0 && cartLocalStorage?.checkout.calculator === calculator) {
            setProductVariant(cartLocalStorage?.productVariant);
        }
    }, []);


    // CONSTRAINTS ----------------------------------------------------------

    //Finished inner copper constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }
        if( technologyId === "" || technologyId <= 2 ){
            setFinishedInnerCuId(1);
        }

        if( technologyId > 2 && finishedInnerCuId === 1 ){
            setFinishedInnerCuId(3);
        }
    }, [technologyId]);

    //Outer copper base constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }

        let previousBasedOuterCuId = basedOuterCuId

        if( technologyId === "" || technologyId < 1 ){
            setBasedOuterCuId(1);
        }

        if( technologyId >= 1  && previousBasedOuterCuId === 1){
            if( technologyId <= 2 ){
                setBasedOuterCuId(4);
            }else{
                setBasedOuterCuId(3);
            }
        }
    }, [technologyId]);

    //Finished outer copper constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }
        handleOuterFinishCopperValue();
    }, [technologyId, basedOuterCuId, ipc3Id]);

    const initialStateOuterFinishCopperItemActive = [true,true,true,true,true,true,true,true,true,true,true,true,true,true];
    let isOuterFinishCopperItemsActive = initialStateOuterFinishCopperItemActive;

    const handleOuterFinishCopperValue = () => {
        isOuterFinishCopperItemsActive = initialStateOuterFinishCopperItemActive;

        if( technologyId === "" || technologyId < 1 ){
            setFinishedOuterCuId(1);
            return
        }
        // CASE BASE SINGLE SIDED
        if(technologyId === 1){

            // CASE OUTER COPPER 35
            if(basedOuterCuId === 4){
                setFinishedOuterCuId(3);
                isOuterFinishCopperItemsActive[2] = false;
                return finishedOuterCuId;
            }

            // CASE OUTER COPPER 70
            if(basedOuterCuId === 5){
                setFinishedOuterCuId(7);
                isOuterFinishCopperItemsActive[6] = false;
                return finishedOuterCuId;
            }
        }

        // CASE IPC 2
        if(ipc3Id === 1){
            // CASE BASE DOUBLE SIDED AND MORE
            if(technologyId >= 2){

                // CASE OUTER COPPER 12
                if(basedOuterCuId === 2){
                    setFinishedOuterCuId(2);
                    isOuterFinishCopperItemsActive[1] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 18
                if(basedOuterCuId === 3){
                    setFinishedOuterCuId(3);
                    isOuterFinishCopperItemsActive[2] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 35
                if(basedOuterCuId === 4){
                    setFinishedOuterCuId(5);
                    isOuterFinishCopperItemsActive[4] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 70
                if(basedOuterCuId === 5){
                    setFinishedOuterCuId(8);
                    isOuterFinishCopperItemsActive[7] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 105
                if(basedOuterCuId === 6){
                    setFinishedOuterCuId(11);
                    isOuterFinishCopperItemsActive[10] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 140
                if(basedOuterCuId === 7){
                    setFinishedOuterCuId(13);
                    isOuterFinishCopperItemsActive[12] = false;
                    return finishedOuterCuId;
                }
            }
        }

        // CASE IPC 3
        if(ipc3Id === 2){
            // CASE BASE DOUBLE SIDED AND MORE
            if(technologyId >= 2){

                // CASE OUTER COPPER 12
                if(basedOuterCuId === 2){
                    setFinishedOuterCuId(3);
                    isOuterFinishCopperItemsActive[2] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 18
                if(basedOuterCuId === 3){
                    setFinishedOuterCuId(4);
                    isOuterFinishCopperItemsActive[3] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 35
                if(basedOuterCuId === 4){
                    setFinishedOuterCuId(6);
                    isOuterFinishCopperItemsActive[5] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 70
                if(basedOuterCuId === 5){
                    setFinishedOuterCuId(9);
                    isOuterFinishCopperItemsActive[8] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 105
                if(basedOuterCuId === 6){
                    setFinishedOuterCuId(12);
                    isOuterFinishCopperItemsActive[11] = false;
                    return finishedOuterCuId;
                }

                // CASE OUTER COPPER 140
                if(basedOuterCuId === 7){
                    setFinishedOuterCuId(14);
                    isOuterFinishCopperItemsActive[13] = false;
                    return finishedOuterCuId;
                }
            }
        }

        // Initialization
        setFinishedOuterCuId(1);
        return finishedOuterCuId;
    }

    //Plugging via constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }
        if(technologyId <= 2){
            setPluggingViaId(1);
            setPluggingViaId(1);
        }
    }, [technologyId]);

    //Via filling Material constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }
        if(pluggingViaId === "" || pluggingViaId === 1){
            setResinFillingId(1);
        }

        if( pluggingViaId > 1 && resinFillingId === 1 ){
            setResinFillingId(2);
        }
    }, [pluggingViaId]);

    //Solder mask color constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }
        let previousColorId = solderMaskColorId;
        if( solderMaskId === "" || solderMaskId === 1 ){
            setSolderMaskColorId(1);
            return
        }
        if ( previousColorId === 1){
            setSolderMaskColorId(2);
        }
    }, [solderMaskId]);

    //legend color constraint
    useEffect(() => {
        if (isLockFields) {
            return
        }
        let previousColorId = legendColorId;
        if( legendId === "" || legendId === 1 ){
            setLegendColorId(1);
            return
        }
        if ( previousColorId === 1){
            setLegendColorId(2);
        }
    }, [legendId]);


    return (
        <Box className={repeatOrderMode.background ? "calculator-wrapper calculator-repeat-order" : "calculator-wrapper"}>
            {/*//once the product variant is received, go to /cart*/}
            {Object.keys(productVariant).length > 0 && !repeatOrderMode.enabled && <Navigate to="/cart" replace={false}/>}

            {/*PROGRESS BAR CONNECT TO BO*/}
            { connectToBO ? ( <BackendConnection/> ) : "" }


            {/* POPUP REPEAT ORDER */}
            <Dialog open={openLoadPcbReferencePopup} onClose={handleCancelLoadReference} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="alertTitle">
                    { repeatOrdersSelectedAvailable.length > 0 ? t("popup.repeatOrder.title") : "Load a reference" }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        {repeatOrdersSelectedAvailable.length > 0 ?
                            <FormControl>
                                <FormLabel id="repeat-orders-selected-radio-buttons-group-label" sx={{fontWeight:"bold"}}>{customerReferenceChoose.title}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="repeat-orders-selected-radio-buttons-group-label"
                                    name="repeat-orders-selected-radio-buttons-group"
                                    onChange={(e) => {setIdOrder(e.target.value); setIsAvailableOrderSelected(true)}}
                                >
                                    {
                                        repeatOrdersSelectedAvailable.map((order, key) => {

                                            const quantity = `${order.pcbQuantity} ${ order.pcbQuantity > 1 ? t("common.pcbs") : t("common.pcb")}`
                                            const orderReference = order.reference ? order.reference : "No order reference"

                                            const label = `${order.number} - ${orderReference} - ${quantity}`;

                                            return <FormControlLabel key={key} value={order.id} control={<Radio />} label={label} />
                                        })

                                    }
                                </RadioGroup>
                            </FormControl>
                            :
                            <>
                                <span style={{fontWeight: "bold"}}>
                                    {customerReferenceChoose.title} 
                                </span>
                                {t("popup.repeatOrder.content")}
                            </>
                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeOutlined onClick={handleCancelLoadReference}>
                        {t("cta.cancel")}
                    </ButtonIcapeOutlined>
                    <ButtonIcape onClick={handleValidLoadReference}
                                 disabled={ repeatOrdersSelectedAvailable.length > 0 && !isAvailableOrderSelected }>
                        {t("cta.load")}
                    </ButtonIcape>
                </DialogActions>
            </Dialog>

            {/* POPUP JAYDA */}
            <Dialog open={isJaydaPopupOpen} onClose={() => setIsJaydaPopupOpen(false)} aria-labelledby="Pcb not quotable">
                <DialogTitle>
                    {t("popup.jaydaCantQuote.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("popup.jaydaCantQuote.content")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeOutlined onClick={() => setIsJaydaPopupOpen(false)}>Close</ButtonIcapeOutlined>
                    <ButtonIcape onClick={() => window.location.href = 'mailto:quickturn@icape-group.com'} autoFocus>
                        {t("cta.contactUs")}
                    </ButtonIcape>
                </DialogActions>
            </Dialog>

            {/* CALCULATOR RIGIDA E LITALI E LA */}
            <Box className={"calculator"}>
                {/*SNACKBAR CONNECT TO BO FAILED*/}
                <Snackbar anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                          open={errorBackendConnection || isErrorConnectToJayda}
                          key='topright'
                          sx={{ width: '100%' }}
                          autoHideDuration={10000}
                          TransitionComponent={snackbarTransition}
                          onClose={() => {
                              setErrorBackendConnection(false);
                              setIsErrorConnectToJayda(false);
                          }}>
                    <Alert severity="error" variant="filled"
                           onClose={() => {
                               setErrorBackendConnection(false);
                               setIsErrorConnectToJayda(false);
                           }}>
                        {t("popup.failedConnectBackend")}
                    </Alert>
                </Snackbar>

                {/*TITLE*/}
                <Typography variant={'h1'}>
                    {t("calculators.rigid.calculatorTitle")}
                </Typography>

                {/*STEPPER STEP TITLE & TOOLBAR*/}
                {!showPanelization &&
                    <>
                        {/*STEPPER*/}
                        <CalculatorStepper
                            jayda={{analyzeInProgress, progress}}
                            field={{checkIfAllFieldsAreFilled}}
                            step={{activeStep, setActiveStep, completed, steps}}
                        />

                        {/*STEP TITLE & TOOLBAR*/}
                        <Box className="title-wrapper">
                            {/*STEP TITLE*/}
                            <Typography variant={'h2'}>
                                {steps[activeStep]}
                                {repeatOrderMode.background &&
                                    <>
                                        <br/>
                                        <span>
                                    {/*TODO TRANSLATE*/}
                                            Repeat order process
                                </span>
                                    </>
                                }
                            </Typography>

                            {/*TOOLBAR*/}
                            <CalculatorToolbar
                                autocomplete={{
                                    setConnectToBO,
                                    customerReferenceChoose,
                                    openCustomerPriceOffersList, setOpenCustomerPriceOffersList,
                                    customerReferences, setCustomerReferences,
                                    loadingForCustomerPriceOffers2, loadingForCustomerPriceOffers,
                                    isCustomerReferenceFieldEmpty,
                                    setCustomerReferenceChoose,
                                    setOpenROAddReferenceDialog,
                                    setROAddReferenceDialogValue,
                                    setIsCustomerReferenceFieldEmpty,
                                    rOAddReferenceDialogValue,
                                    isPcbRefAlreadyExisting, setIsPcbRefAlreadyExisting,
                                    addReferenceDialogCallingButton,
                                    openROAddReferenceDialog, setOpenLoadPcbReferencePopup,
                                    setRepeatOrdersSelectedAvailable
                                }}
                                field={{
                                    quantity, setQuantity,
                                    panelQuantity, setPanelQuantity,
                                    unitFormat, setUnitFormat,
                                    setPartNumber,
                                    isPcbAreaOversized,
                                    lockCustomPanelizationFields,
                                    isLockFields,
                                    repeatOrderMode,
                                    pcbDeliveryFormat,
                                }}
                                function={{
                                    handleAddNewReference,
                                    handleDownloadQuote,
                                    handleNewProject,
                                    handleOrderNow,
                                    handleUnlockFields,
                                    updateRepeatOrder,
                                }}
                            />
                        </Box>
                    </>}

                {isPcbAreaOversized ?
                    <Typography component="span" id="error-pcb-area">
                        {t("calculators.toolbar.oversizeMessage")}&nbsp;
                        {unitFormat === "mm" ? "50 m²" : "77 500 in²"}
                    </Typography>
                    : null}

                {/*STEP RENDERER*/}
                <Box className={"content-wrapper"}>
                    {isLockFields && activeStep !== 2 &&
                        <Box className="lock-repeat-order">
                            <LockIcon />
                        </Box>
                    }
                    {activeStep === 0 ?
                        <Step1 handleNewProject={handleNewProject}
                               handleDropFile={handleDropFile}
                               showPanelization={showPanelization}
                               setShowPanelization={setShowPanelization}/>
                        : activeStep === 1 ?
                            <Step2 isOuterFinishCopperItemsActive = {isOuterFinishCopperItemsActive}/>
                            :
                            <Step3 handleStencilUpdateOnLockedPrice={handleStencilUpdateOnLockedPrice}
                                   updateRepeatOrder={updateRepeatOrder}/>
                    }
                </Box>

                {/*NEXT BUTTON DESKTOP*/}
                {!showPanelization &&
                    <StepNavigation
                        checkout={{checkoutProcess}}
                        device={{isDesktop: true}}
                        field={{checkIfAllFieldsAreFilled}}
                        google={{gtmCalculatorStepEvent}}
                        pcb={{isPcbAreaOversized}}
                        repeatOrderMode={{repeatOrderMode}}
                        service={{connectToBO}}
                        step={{activeStep, setActiveStep,
                            completed, setCompleted,
                            steps, setIsStepOneToFinalize,
                            stepOneToBeFilled}}
                    />
                }

            </Box>

            {/*PROJECT PANEL*/}
            <ProjectPanel prices={{
                pcbWidth, pcbLength,
                pcbWidthIn, pcbLengthIn,
                pcbPerPanel, panelQuantity,
                unitFormat, pcbDeliveryFormat,
                unitPrice_PP, total_PP, stencil_PP,
                quantity,
                incoterm_PP,
                requestedDate, minDeliveryDate,
                stencilTypeId, priceMatrix,
                repeatOrderMode, loadCurrency,
                country, tariff, batchSize}}
            />


            {/*NEXT BUTTON TABLET MOBILE*/}
            {!showPanelization &&
                <StepNavigation
                    checkout={{checkoutProcess}}
                    device={{isDesktop: false}}
                    field={{checkIfAllFieldsAreFilled}}
                    google={{gtmCalculatorStepEvent}}
                    pcb={{isPcbAreaOversized}}
                    repeatOrderMode={{repeatOrderMode}}
                    service={{connectToBO}}
                    step={{activeStep, setActiveStep,
                        completed, setCompleted,
                        steps, setIsStepOneToFinalize,
                        stepOneToBeFilled}}
                />
            }
        </Box>
    );
}

export default CalculatorRigid;
