export function isHoliday(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // Jours fériés en France pour le mois de mai 2024
    const holidays = [
        // Le 1er mai : Fête du Travail
        new Date(2024, 4, 1),
        // Le 8 mai : Victoire 1945
        new Date(2024, 4, 8),
        // Le 9 mai : Ascension
        new Date(2024, 4, 9),
        // Le 20 mai : Lundi de Pentecôte
        new Date(2024, 4, 20),
        // Le 10 juin : Dragon boat
        new Date(2024, 5, 10),
        // Assomption mon khey
        new Date(2024, 7, 15),
        // New Year 2025
        new Date(2025, 0, 1),
    ];

    // Vérifie si la date est un jour férié
    for (let holiday of holidays) {
        if (day === holiday.getDate() && month === holiday.getMonth() && year === holiday.getFullYear()) {
            return true;
        }
    }

    return false;
}