import React, {useContext, useEffect, useState} from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import PcbBox from "./pcbBox/PcbBox";
import "./panelization.scss";
import {ButtonIcapeGreen, ButtonIcapeOutlined} from "../../../button/button-icape";
import {Close, Replay} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {inchToMm, mmToInch} from "../../../../tools/unit.utils";
import {useTranslation} from "react-i18next";
import html2canvas from "html2canvas";
import {logInConsole} from "../../../../tools/debug";


export default function Panelization(props) {
    const handleShowPanelization = props.handleShowPanelization;
    const handleSavePanelization = props.handleSavePanelization;
    const lockCustomPanelizationFields = props.lockCustomPanelizationFields;
    const isLockFields = props.isLockFields;
    const unitFormat = props.unitFormat;
    const customPanelizationSettings = props.size.customPanelizationSettings;

    const {t} = useTranslation();

    // 550 / 600
    const panelMaximumWidth = 550;
    const panelMaximumHeight = 600;
    const panelMaximumWidthA4 = 210;
    const panelMaximumHeightA4 = 297;
    const panelMinimumWidth = 50;
    const panelMinimumHeight = 50;
    const spaceMax = panelMaximumWidth / 2;
    const borderMax = panelMaximumWidth / 2;
    const pcbWidthMinSize = 10;
    const pcbHeightMinSize = 15;
    const pcbWidthMax = 480;
    const pcbHeightMax = 580;

    // inch values
    const panelMaximumWidthIn = parseFloat(mmToInch(panelMaximumWidth));
    const panelMaximumHeightIn = parseFloat(mmToInch(panelMaximumHeight));
    const panelMinimumWidthIn = parseFloat(mmToInch(panelMinimumWidth));
    const panelMinimumHeightIn = parseFloat(mmToInch(panelMinimumHeight));
    const spaceMaxIn = parseFloat(mmToInch(spaceMax));
    const borderMaxIn = parseFloat(mmToInch(borderMax));
    const pcbWidthMinSizeIn = parseFloat(mmToInch(pcbWidthMinSize));
    const pcbHeightMinSizeIn = parseFloat(mmToInch(pcbHeightMinSize));
    const pcbWidthMaxIn = parseFloat(mmToInch(pcbWidthMax));
    const pcbHeightMaxIn = parseFloat(mmToInch(pcbHeightMax));


    const pw = {
        'mm': panelMinimumWidth,
        'in': panelMinimumWidthIn,
    }
    const ph = {
        'mm': panelMinimumHeight,
        'in': panelMinimumHeightIn,
    }
    const [panelWidth, setPanelWidth] = useState(pw);
    const [panelHeight, setPanelHeight] = useState(ph);


    const [pcbQuantityX, setPcbQuantityX] = useState(customPanelizationSettings.pcbQuantityX || 1);
    const [pcbQuantityY, setPcbQuantityY] = useState(customPanelizationSettings.pcbQuantityY || 1);

    // REVERT TO LOCAL STORAGE OR 50 TO GO BACK ALRICK
    const pcbWidthChoseByUser = props.size.pcbWidth !== '' ? props.size.pcbWidth : 100;
    const pcbHeightChoseByUser = props.size.pcbLength !== '' ? props.size.pcbLength : 100;
    const pcbWidthInChoseByUserIn = props.size.pcbWidthIn !== '' ? props.size.pcbWidthIn : 3.94;
    const pcbHeightInChoseByUserIn = props.size.pcbLengthIn !== '' ? props.size.pcbLengthIn : 3.94;

    const [pcbWidth, setPcbWidth] = useState(pcbWidthChoseByUser);
    const [pcbHeight, setPcbHeight] = useState(pcbHeightChoseByUser);
    const [pcbWidthIn, setPcbWidthIn] = useState(pcbWidthInChoseByUserIn);
    const [pcbHeightIn, setPcbHeightIn] = useState(pcbHeightInChoseByUserIn);


    const [spaceX, setSpaceX] = useState(customPanelizationSettings.mm?.space.x || 0);
    const [spaceY, setSpaceY] = useState(customPanelizationSettings.mm?.space.y || 0);
    const [spaceXIn, setSpaceXIn] = useState(customPanelizationSettings.in?.space.x || 0);
    const [spaceYIn, setSpaceYIn] = useState(customPanelizationSettings.in?.space.y || 0);


    const [technicalBorderTop, setTechnicalBorderTop] = useState(customPanelizationSettings.mm?.technicalBorder.top || 0);
    const [technicalBorderBottom, setTechnicalBorderBottom] =useState(customPanelizationSettings.mm?.technicalBorder.bottom || 0);
    const [technicalBorderLeft, setTechnicalBorderLeft] =useState(customPanelizationSettings.mm?.technicalBorder.left || 0);
    const [technicalBorderRight, setTechnicalBorderRight] =useState(customPanelizationSettings.mm?.technicalBorder.right || 0);
    const [technicalBorderTopIn, setTechnicalBorderTopIn] =useState(customPanelizationSettings.in?.technicalBorder.top || 0);
    const [technicalBorderBottomIn, setTechnicalBorderBottomIn] =useState(customPanelizationSettings.in?.technicalBorder.bottom || 0);
    const [technicalBorderLeftIn, setTechnicalBorderLeftIn] =useState(customPanelizationSettings.in?.technicalBorder.left || 0);
    const [technicalBorderRightIn, setTechnicalBorderRightIn] =useState(customPanelizationSettings.in?.technicalBorder.right || 0);

    const [separationX, setSeparationX] = useState(customPanelizationSettings.separationX || 1);
    const [separationY, setSeparationY] = useState(customPanelizationSettings.separationY || 1);

    const [vCutPositionX, setVCutPositionX] = useState([]);
    const [vCutPositionY, setVCutPositionY] = useState([]);

    const handlePcbQuantityX = (newValue) => {
        let totalWidth = (pcbWidth * newValue) + (spaceX + (newValue - 1)) + technicalBorderLeft + technicalBorderRight
        if( totalWidth <= panelMaximumWidth){
            setPcbQuantityX(parseInt((newValue < 1 || isNaN(newValue)) ? 1 : newValue));
        }
    };
    const handlePcbQuantityY = (newValue) => {
        let totalHeight = (pcbHeight * newValue) + (spaceY + (newValue - 1)) + technicalBorderTop + technicalBorderBottom
        if( totalHeight <= panelMaximumHeight){
            setPcbQuantityY(parseInt((newValue < 1 || isNaN(newValue)) ? 1 : newValue));
        }
    };

    // error
    const [isErrorSize, setIsErrorSize] = useState(false);

    const millingCheck = (_separation, _space) => {
        let milling = 0;
        if (_separation === 3) {
            milling = (_space !== 0) ? 4 : 2;
        }
        return milling;
    }


    //WIDTH
    const handleMmInchPcbWidthPanelization = (newWidth) => {
        if (unitFormat === 'mm'){

            newWidth = newWidth > pcbWidthMax ? pcbWidthMax : newWidth;
            newWidth = newWidth <= 0 || isNaN(newWidth) ? pcbWidthMinSize : newWidth;
            newWidth < pcbWidthMinSize ? setIsErrorSize(true) : setIsErrorSize(false);

            setPcbWidth(newWidth);
            setPcbWidthIn(mmToInch(newWidth));

            //PCB quantity update to avoid an outsize panel
            let totalWidth = (newWidth * pcbQuantityX) + (spaceX + (pcbQuantityX - 1)) + technicalBorderLeft + technicalBorderRight
            panelWidth['mm'] > panelMaximumWidth && setPcbQuantityX(parseInt(totalWidth / panelMaximumWidth));

        }  else {
            newWidth = newWidth > pcbWidthMaxIn ? pcbWidthMaxIn : newWidth;
            newWidth = newWidth <= 0 || isNaN(newWidth) ? pcbWidthMinSizeIn : newWidth;
            newWidth < pcbWidthMinSizeIn ? setIsErrorSize(true) : setIsErrorSize(false);

            setPcbWidth(Math.round(inchToMm(newWidth)));
            setPcbWidthIn(newWidth);

            //PCB quantity update to avoid an outsize panel
            let totalWidthIn = (newWidth * pcbQuantityX) + (spaceXIn + (pcbQuantityX - 1)) + technicalBorderLeftIn + technicalBorderRightIn
            panelWidth['in'] > panelMaximumWidthIn && setPcbQuantityX(parseInt(totalWidthIn / panelMaximumWidth));
        }

    };
    const handleSpaceX = (newValue) => {
        if(unitFormat === 'mm'){
            newValue = newValue > spaceMax ? spaceMax : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setSpaceX(parseInt(newValue));
            setSpaceXIn(mmToInch(newValue));
        }else{
            newValue = newValue > spaceMaxIn ? spaceMaxIn : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setSpaceX(parseInt(inchToMm(newValue)));
            setSpaceXIn(newValue);
        }
    };
    const handleTechnicalBorderLeft = (newValue) => {
        if(unitFormat === 'mm'){
            newValue = (newValue > borderMax) ? borderMax : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderLeft(parseInt(newValue));
            setTechnicalBorderLeftIn(mmToInch(newValue));
        }else{
            newValue = (newValue > borderMaxIn) ? borderMaxIn : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderLeft(parseInt(inchToMm(newValue)));
            setTechnicalBorderLeftIn(newValue);
        }
    };
    const handleTechnicalBorderRight = (newValue) => {
        if(unitFormat === 'mm'){
            newValue = (newValue > borderMax) ? borderMax : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderRight(parseInt(newValue));
            setTechnicalBorderRightIn(mmToInch(newValue));
        }else{
            newValue = (newValue > borderMaxIn) ? borderMaxIn : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderRight(parseInt(inchToMm(newValue)));
            setTechnicalBorderRightIn(newValue);
        }
    };
    const optimizeWidth = (_pcbWidth, _pcbWidthIn, _spaceX, _spaceXIn, _technicalBorderLeft, _technicalBorderRight, _separationX) => {

        let separationSizeXTemp = millingCheck(separationX, spaceX);

        //pcb quantity X
        let pcbQuantityXTemp = Math.trunc((panelMaximumWidthA4 - _technicalBorderLeft - _technicalBorderRight + separationSizeXTemp + _spaceX) / (_pcbWidth + _spaceX + separationSizeXTemp));
        setPcbQuantityX(pcbQuantityXTemp);

    };


    //HEIGHT
    const handleMmInchPcbHeightPanelization = (newHeight) => {
        if(unitFormat === 'mm'){
            newHeight = newHeight > pcbHeightMax ? pcbHeightMax : newHeight;
            newHeight = newHeight <= 0 || isNaN(newHeight) ? pcbHeightMinSize : newHeight;
            newHeight < pcbHeightMinSize ? setIsErrorSize(true) : setIsErrorSize(false);

            setPcbHeight(newHeight);
            setPcbHeightIn(mmToInch(newHeight));

            //PCB quantity update to avoid an outsize panel
            let totalHeight = (newHeight * pcbQuantityY) + (spaceY + (pcbQuantityY - 1)) + technicalBorderTop + technicalBorderBottom
            panelHeight['mm'] > panelMaximumHeight && setPcbQuantityY(parseInt(totalHeight / panelMaximumHeight));

        }else{
            newHeight = newHeight > pcbHeightMaxIn ? pcbHeightMaxIn : newHeight;
            newHeight = newHeight <= 0 || isNaN(newHeight) ? pcbHeightMinSizeIn : newHeight;
            newHeight < pcbHeightMinSizeIn ? setIsErrorSize(true) : setIsErrorSize(false);

            setPcbHeight(Math.round(inchToMm(newHeight)));
            setPcbHeightIn(newHeight);

            //PCB quantity update to avoid an outsize panel
            let totalHeightIn = (newHeight * pcbQuantityY) + (spaceYIn + (pcbQuantityY - 1)) + technicalBorderTopIn + technicalBorderBottomIn
            panelWidth['in'] > panelMaximumHeightIn && setPcbQuantityY(parseInt(totalHeightIn / panelMaximumHeightIn));
        }
    };
    const handleSpaceY = (newValue) => {
        if(unitFormat === 'mm'){
            newValue = newValue > spaceMax ? spaceMax : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setSpaceY(parseInt(newValue));
            setSpaceYIn(mmToInch(newValue));
        }else{
            newValue = newValue > spaceMaxIn ? spaceMaxIn : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setSpaceY(parseInt(inchToMm(newValue)));
            setSpaceYIn(newValue);
        }
    };
    const handleTechnicalBorderTop = (newValue) => {
        if(unitFormat === 'mm'){
            newValue = (newValue > borderMax) ? borderMax : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderTop(parseInt(newValue));
            setTechnicalBorderTopIn(mmToInch(newValue));
        }else{
            newValue = (newValue > borderMaxIn) ? borderMaxIn : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderTop(parseInt(inchToMm(newValue)));
            setTechnicalBorderTopIn(newValue);
        }
    };
    const handleTechnicalBorderBottom = (newValue) => {
        if(unitFormat === 'mm'){
            newValue = (newValue > borderMax) ? borderMax : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderBottom(parseInt(newValue));
            setTechnicalBorderBottomIn(mmToInch(newValue));
        }else{
            newValue = (newValue > borderMaxIn) ? borderMaxIn : newValue;
            newValue = newValue <= 0 || isNaN(newValue) ? 0 : newValue;

            setTechnicalBorderBottom(parseInt(inchToMm(newValue)));
            setTechnicalBorderBottomIn(newValue);
        }
    };
    const optimizeHeight = (_pcbHeight, _spaceY, _technicalBorderTop, _technicalBorderBottom, _separationY) => {

        let separationSizeYTemp = millingCheck(separationY, spaceY);

        //pcb quantity X
        let pcbQuantityYTemp = Math.trunc((panelMaximumHeightA4 - _technicalBorderTop - _technicalBorderBottom + separationSizeYTemp + _spaceY) / (_pcbHeight + _spaceY + separationSizeYTemp));
        setPcbQuantityY(pcbQuantityYTemp);

    }



    //OPTIMIZE
    const optimizePanel = () => {
        optimizeWidth(pcbWidth, pcbWidthIn , spaceX, spaceXIn, technicalBorderLeft, technicalBorderRight, separationX);
        optimizeHeight(pcbHeight, spaceY, technicalBorderTop, technicalBorderBottom, separationY);
    }


    //CHECK FIELDS
    useEffect(() => {

        //console.log("check fields");

        //milling
        let separationSizeXTemp = millingCheck(separationX, spaceX);
        let separationSizeYTemp = millingCheck(separationY, spaceY);

        //vCut positioning X
        let vCutPosition_x = [technicalBorderLeft, technicalBorderLeft + pcbWidth]
        for (let i = 1; i < pcbQuantityX; i++) {
            vCutPosition_x.push(technicalBorderLeft + (pcbWidth * i) + spaceX * i)
            vCutPosition_x.push(technicalBorderLeft + (pcbWidth * i) + spaceX * i + pcbWidth)
        }
        setVCutPositionX(vCutPosition_x);

        //vCut positioning Y
        let vCutPosition_y = [technicalBorderTop, technicalBorderTop + pcbHeight]
        for (let i = 1; i < pcbQuantityY; i++) {
            vCutPosition_y.push(technicalBorderTop + (pcbHeight * i) + spaceY * i)
            vCutPosition_y.push(technicalBorderTop + (pcbHeight * i) + spaceY * i + pcbHeight)
        }
        setVCutPositionY(vCutPosition_y);


        //TODO HANDLE PANEL SIZE IN INCHES PROPERLY
        //panel width
        let newPanelWidth = (pcbQuantityX * pcbWidth) + ((separationSizeXTemp * (pcbQuantityX - 1)) + spaceX * (pcbQuantityX - 1) + technicalBorderLeft + technicalBorderRight);
        setPanelWidth({'mm': newPanelWidth, 'in': parseFloat(mmToInch(newPanelWidth))});

        //panel height
        let newPanelHeight = (pcbQuantityY * pcbHeight) + ((separationSizeYTemp * (pcbQuantityY - 1)) + spaceY * (pcbQuantityY - 1) + technicalBorderTop + technicalBorderBottom);
        setPanelHeight({'mm': newPanelHeight, 'in': parseFloat(mmToInch(newPanelHeight))});


    }, [pcbQuantityX, pcbQuantityY, pcbWidth , spaceX, technicalBorderLeft, technicalBorderRight, separationX, pcbHeight, spaceY, technicalBorderTop, technicalBorderBottom, separationY]);


    // HANDLE DATA
    const handleSave = () => {
        let dataToExport = (getData());
        captureImage().then((panelizationScreenshot) => {
            const panelizationSettings = dataToExport.panelizationSettings;
            dataToExport = {
                ...dataToExport,
                panelizationSettings: {
                    ...panelizationSettings,
                    panelizationScreenshot: panelizationScreenshot,
                }
            };
            handleSavePanelization(dataToExport);
        });
    }

    const getData = () => {

        let exportSeparationX = 1;
        let exportSeparationY = 1;
        if (separationX === "v-cut"){
            exportSeparationX = 2;
        } else if (separationX === "milling-internal-whole") {
            exportSeparationX = 3;

        }
        if (separationY === "v-cut"){
            exportSeparationY = 2;
        } else if (separationX === "milling-internal-whole") {
            exportSeparationY = 3;

        }

        return {
            isErrorSize,
            quantityPcbPanelization: (pcbQuantityX * pcbQuantityY),
            pcbWidth, pcbHeight,
            pcbWidthIn, pcbHeightIn,
            panelWidth, panelHeight,
            panelizationSettings: {
                "mm": {
                    "space": {
                        "x": spaceX,
                        "y": spaceY,
                    },
                    "technicalBorder": {
                        "top" : technicalBorderTop,
                        "right" : technicalBorderRight,
                        "bottom" : technicalBorderBottom,
                        "left" : technicalBorderLeft,
                    }
                },
                "in": {
                    "space": {
                        "x": spaceXIn,
                        "y": spaceYIn,
                    },
                    "technicalBorder": {
                        "top" : technicalBorderTopIn,
                        "right" : technicalBorderRightIn,
                        "bottom" : technicalBorderBottomIn,
                        "left" : technicalBorderLeftIn,
                    }
                },
                separationX,
                separationY,
                pcbQuantityX,
                pcbQuantityY,
                "panelizationScreenshot": 'no screenshot'
            }
        }
    }
    const resetData = () => {
        setPcbWidth(pcbWidthChoseByUser);
        setPcbHeight(pcbHeightChoseByUser);
        setPcbWidthIn(pcbWidthInChoseByUserIn);
        setPcbHeightIn(pcbHeightInChoseByUserIn);
        setSpaceX(0);
        setSpaceY(0);
        setSpaceXIn(0);
        setSpaceYIn(0);
        setTechnicalBorderTop(0);
        setTechnicalBorderRight(0);
        setTechnicalBorderBottom(0);
        setTechnicalBorderLeft(0);
        setTechnicalBorderTopIn(0);
        setTechnicalBorderRightIn(0);
        setTechnicalBorderBottomIn(0);
        setTechnicalBorderLeftIn(0);
        setSeparationX(1);
        setSeparationY(1);
        setPcbQuantityX(1);
        setPcbQuantityY(1);
    }


    // CHECK ERROR
    const checkErrorWidth = (pcbWidth, pcbWidthIn) => {
        if(pcbWidth < pcbWidthMinSize || pcbWidthIn < pcbWidthMinSizeIn){
            return true;
        }
    };
    const checkErrorHeight = (pcbHeight, pcbHeightIn) => {
        if(pcbHeight < pcbHeightMinSize || pcbHeightIn < pcbHeightMinSizeIn){
            return true;
        }
    };


    // PANEL CHECK ERROR
    useEffect(() => {
        if (unitFormat === 'mm') {
            if ((panelWidth.mm > panelMaximumWidth) || (panelHeight.mm > panelMaximumHeight)) {
                setIsErrorSize(true);
            } else {
                setIsErrorSize(false);
            }
        } else {
            if ((panelWidth.in > panelMaximumWidth) || (panelHeight.in > panelMaximumHeight)) {
                setIsErrorSize(true);
            } else {
                setIsErrorSize(false);
            }
        }
    }, [panelWidth, panelHeight]);


    //SAVE PANELIZATION TO PDF
    const captureImage = async () => {
        logInConsole(`Capturing screeshot`, 'orange');
        try {
            const targetElement = document.getElementById('elementToCapture');
            const canvas = await html2canvas(targetElement);
            return canvas.toDataURL('image/png'); // L'image au format base64
        } catch (error) {
            console.error('Panelization screenshot error  :', error);
        }
    };


    return (
        <Box className="panelization-wrapper">
            {/*TOP BAR*/}
            <Box className="panelization-top-bar">
                <Typography variant={'h3'}>{t("calculators.panelization.title")}</Typography>
                {!isLockFields &&
                    <Box>
                        <Box title="Reset panelization data" onClick={resetData}>
                            <Replay/>
                        </Box>
                        <Box title="Close customisation tool without saving" onClick={handleShowPanelization}>
                            <Close/>
                        </Box>
                    </Box>
                }
            </Box>

            {/*PANEL DIMENSIONS*/}
            <Box className="panelization-dimensions">
                <Box sx={{textAlign: "left", fontWeight: "bold"}}>
                    {t("calculators.panelization.actualPanelDimensions")}
                    {panelWidth[unitFormat]} x {panelHeight[unitFormat]}
                </Box>
                <Box sx={{textAlign: "left"}}>
                    {t("calculators.panelization.maxPanelDimensions")}
                    {unitFormat === 'mm' ? panelMaximumWidth : panelMaximumWidthIn} x {unitFormat === 'mm' ? panelMaximumHeight : panelMaximumHeightIn}
                </Box>
            </Box>

            {/*PANELIZATION*/}
            <Box className="panelization-content-wrapper">

                {/*PURPLE BOXES*/}
                <Box id="elementToCapture" className="panelization-render-wrapper">
                    <Box className="panelization-render-content">
                        {/*VCUT*/}
                        <Box className="panelization-render-vcut"
                             sx={{
                                 position: "relative",
                                 left: `-${(pcbQuantityX * pcbWidth + (spaceX * (pcbQuantityX - 1))) / 2 + ((technicalBorderLeft + technicalBorderRight) / 2)}px`,
                             }}>
                            {separationY === 2 && (
                                Array.from({length: vCutPositionY.length}, (_, i) => (
                                    //horizontal rows
                                    <Box key={i} className="v-cut" sx={{
                                        position: "absolute",
                                        height: "2px",
                                        top: vCutPositionY[i],
                                        left: 0,
                                        width: `${pcbQuantityX * pcbWidth + (spaceX * (pcbQuantityX - 1)) + technicalBorderRight + technicalBorderLeft}px`
                                    }}></Box>
                                ))
                            )}
                            {separationX === 2 && (
                                Array.from({length: vCutPositionX.length}, (_, j) => (
                                    //vertical rows
                                    <Box key={j} className="v-cut" sx={{
                                        position: "absolute",
                                        width: "2px",
                                        left: vCutPositionX[j],
                                        top: 0,
                                        height: `${pcbQuantityY * pcbHeight + (spaceY * (pcbQuantityY - 1)) + technicalBorderTop + technicalBorderBottom}px`,
                                    }}></Box>
                                ))
                            )}
                        </Box>

                        {/*PCB BOX*/}
                        <Box className="panelization-pcb-box" sx={{
                            gap: `${spaceY}px`,
                            paddingTop: `${technicalBorderTop}px`,
                            paddingBottom: `${technicalBorderBottom}px`,
                            paddingLeft: `${technicalBorderLeft}px`,
                            paddingRight: `${technicalBorderRight}px`
                        }}>
                            {Array.from({length: pcbQuantityY}, (_, i) => (
                                <Box key={i}
                                     sx={{
                                         display: "flex",
                                         gap: `${spaceX}px`
                                     }}
                                >
                                    {Array.from({length: pcbQuantityX}, (_, j) => (
                                        <PcbBox key={j}
                                                width={pcbWidth}
                                                height={pcbHeight}
                                                separationX={separationX}
                                                separationY={separationY}
                                                isSpaceX={(spaceX !== 0)}
                                                isSpaceY={(spaceY !== 0)}
                                                isFirstX={(j === 0)}
                                                isLastX={(j === pcbQuantityX - 1)}
                                                isFirstY={(i === 0)}
                                                isLastY={(i === pcbQuantityY - 1)}
                                                isTechnicalBorderTop={(technicalBorderTop > 0)}
                                                isTechnicalBorderBottom={(technicalBorderBottom > 0)}
                                                isTechnicalBorderLeft={(technicalBorderLeft > 0)}
                                                isTechnicalBorderRight={(technicalBorderRight > 0)}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>

                {/*FIELDS*/}
                <Box className="panelization-fields-wrapper">

                    {/*PCB SIZES*/}
                    <Box className="panelization-field">
                        <Typography component={"span"}>{t("calculators.panelization.pcbSize")}</Typography>
                        <Box>
                            <TextField
                                id="pcbWidth"
                                label={t("calculators.step1.fields.pcbWidth")}
                                type="number"
                                size="small"
                                value={unitFormat === 'mm' ? pcbWidth : pcbWidthIn}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                onChange={e => handleMmInchPcbWidthPanelization(parseFloat(e.target.value))}
                                error={checkErrorWidth(pcbWidth, pcbWidthIn)}
                                disabled={isLockFields}
                            />
                            <TextField
                                id="pcbHeight"
                                label={t("calculators.step1.fields.pcbLength")}
                                type="number"
                                size="small"
                                value={unitFormat === 'mm' ? pcbHeight : pcbHeightIn}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                onChange={e => handleMmInchPcbHeightPanelization(parseFloat(e.target.value))}
                                error={checkErrorHeight(pcbHeight, pcbHeightIn)}
                                disabled={isLockFields}
                            />
                        </Box>
                    </Box>

                    {/*SPACE BETWEEN PCB*/}
                    <Box className="panelization-field">
                        <Typography component={"span"}>{t("calculators.panelization.spaceBetweenPcb")}</Typography>
                        <Box>
                            <TextField
                                id="spaceX-number"
                                label={t("calculators.panelization.space") + " X"}
                                type="number"
                                size="small"
                                min={0}
                                value={unitFormat === 'mm' ? spaceX : spaceXIn}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment className="end-adornment-textfield" position="end">{unitFormat}</InputAdornment>,
                                }}
                                onChange={e => handleSpaceX(parseFloat(e.target.value))}
                                disabled={isLockFields}
                            />
                            <TextField
                                id="spaceY-number"
                                label={t("calculators.panelization.space") + " Y"}
                                type="number"
                                size="small"
                                min={0}
                                value={unitFormat === 'mm' ? spaceY : spaceYIn}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                onChange={e => handleSpaceY(parseFloat(e.target.value))}
                                disabled={isLockFields}
                            />
                        </Box>
                    </Box>

                    {/*TECHNICAL BORDERS*/}
                    <Box className="panelization-field">
                        <Typography component={"span"}>{t("calculators.panelization.technicalBorder")}</Typography>
                        <Box>
                            <TextField
                                id="techBorder_left"
                                label={t("calculators.panelization.left")}
                                type="number"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                value={unitFormat === 'mm' ? technicalBorderLeft : technicalBorderLeftIn}
                                onChange={e => handleTechnicalBorderLeft(parseFloat(e.target.value))}
                                disabled={isLockFields}
                            />
                            <TextField
                                id="techBorder_top"
                                label={t("calculators.panelization.top")}
                                type="number"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                value={unitFormat === 'mm' ? technicalBorderTop : technicalBorderTopIn}
                                onChange={e => handleTechnicalBorderTop(parseFloat(e.target.value))}
                                disabled={isLockFields}
                            />
                        </Box>
                        <Box>
                            <TextField
                                id="techBorder_right"
                                label={t("calculators.panelization.right")}
                                type="number"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                value={unitFormat === 'mm' ? technicalBorderRight :technicalBorderRightIn}
                                onChange={e => handleTechnicalBorderRight(parseFloat(e.target.value))}
                                disabled={isLockFields}
                            />
                            <TextField
                                id="techBorder_bottom"
                                label={t("calculators.panelization.bottom")}
                                type="number"
                                size="small"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{unitFormat}</InputAdornment>,
                                }}
                                value={unitFormat === 'mm' ? technicalBorderBottom : technicalBorderBottomIn}
                                onChange={e => handleTechnicalBorderBottom(parseFloat(e.target.value))}
                                disabled={isLockFields}
                            />
                        </Box>
                    </Box>

                    {/*SEPARATIONS*/}
                    <Box className="panelization-field-separation">
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                {t("calculators.panelization.circuitSeparation")} X
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="separationX"
                                name="row-radio-buttons-group-separation-x"
                                value={separationX.toString()}
                                onChange={(e) => setSeparationX(parseFloat(e.target.value))}
                            >
                                <FormControlLabel
                                    value={1}
                                    control={<Radio/>}
                                    label={t("calculators.panelization.noSeparation")}
                                    disabled={isLockFields}
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio/>}
                                    label={t("calculators.panelization.vCut")}
                                    disabled={isLockFields}
                                />
                                <FormControlLabel
                                    value={3}
                                    control={<Radio/>}
                                    label={t("calculators.panelization.millingStampHole")}
                                    disabled={isLockFields}
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                {t("calculators.panelization.circuitSeparation")} Y
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="separationY"
                                name="row-radio-buttons-group-separation-y"
                                value={separationY.toString()}
                                onChange={(e) => setSeparationY(parseFloat(e.target.value))}
                            >
                                <FormControlLabel
                                    value={1}
                                    control={<Radio/>}
                                    label={t("calculators.panelization.noSeparation")}
                                    disabled={isLockFields}
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio/>}
                                    label={t("calculators.panelization.vCut")}
                                    disabled={isLockFields}
                                />
                                <FormControlLabel
                                    value={3}
                                    control={<Radio/>}
                                    label={t("calculators.panelization.millingStampHole")}
                                    disabled={isLockFields}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {/*PCB QUANTITY*/}
                    <Box className="panelization-field">
                        <Typography component={"span"}>{t("calculators.panelization.pcbQuantity")}</Typography>
                        <Box>
                            <TextField
                                id="X-number"
                                label={t("calculators.panelization.pcbQuantity") + " X"}
                                type="number"
                                size="small"
                                value={pcbQuantityX}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={(e) => handlePcbQuantityX(e.target.value)}
                                disabled={isLockFields}
                            />
                            <TextField
                                id="Y-number"
                                label={t("calculators.panelization.pcbQuantity") + " Y"}
                                type="number"
                                size="small"
                                value={pcbQuantityY}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={(e) => handlePcbQuantityY(e.target.value)}
                                disabled={isLockFields}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/*CTA*/}
            <Box className="panelization-bottom-bar">
                <ButtonIcapeGreen
                    onClick={optimizePanel}
                    disabled={isLockFields}>
                    Optimize A4 Format
                </ButtonIcapeGreen>

                {isLockFields ?
                    <ButtonIcapeOutlined
                        onClick={handleShowPanelization}>
                        {t("cta.close")}
                    </ButtonIcapeOutlined>
                    :
                    <ButtonIcapeOutlined
                        onClick={handleSave}
                        disabled={isErrorSize}>
                        {t("cta.savePanel")}
                    </ButtonIcapeOutlined>
                }
            </Box>
        </Box>
    );
}
