import React, {useState, useEffect, useContext} from "react";
import "./login.scss";
import {Box, Checkbox, FormControlLabel, FormGroup, IconButton, InputAdornment, TextField} from "@mui/material";
import LockOpen from '@mui/icons-material/LockOpen';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {axios, BASE_URL} from '../../../api/axios';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import useUser from "../../../hooks/useUser";
import ErrorMessage from "../error-message";
import Typography from "@mui/material/Typography";
import {ButtonIcape} from "../../button/button-icape";
import {useTranslation} from "react-i18next";
import {regex} from "../../../tools/regex";
import {logInConsole} from "../../../tools/debug";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import UserContext from "../../../context/UserProvider";
import {cleanUpLocalStorage} from "../../../tools/cookie-handler";
import {handleConnexionEvent} from "../../../tools/google-tag-manager-events";
import {ErrorHandling} from "../../../tools/error-handling";

//@TODO manage user who have been blocked
//@TODO manage user who have not been activated

const Login = (props) => {
    const {setAuth, persist, setPersist} = useAuth();
    const {setUser} = useUser();
    const {t} = useTranslation();


    const navigate = useNavigate();
    const location = useLocation();
    const {isFirstConnection, setIsFirstConnection} = useContext(UserContext);
    const from = location.state?.from?.pathname || "/";

    const [userName, setUserName] = useState('');
    const [userNameConfirmation, setUserNameConfirmation] = useState('');
    const [userPassword, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [passwordRecoveryMode, setPasswordRecoveryMode] = useState(false);
    const [recoveryMailSent, setRecoveryMailSent] = useState(false);


    const [errMsg, setErrMsg] = useState('');
    const [showErrMsg, setShowErrMsg] = useState(false);


    // NAVIGATION
    useEffect(() => {
        if ( window.location.pathname === '/register' ){
            navigate('/login', {replace: true});
        }
    }, []);


    // FIRST CONNECTION SETUP
    useEffect(() => {
        isFirstConnection && setPasswordRecoveryMode(false)
    }, [isFirstConnection]);


    // ERROR MESSAGE
    useEffect(() => {
        setErrMsg('');
    }, [userName, userPassword]);
    useEffect(() => {
        if (errMsg !== '') {
            setShowErrMsg(true)
        } else {
            setShowErrMsg(false)
        }
    }, [errMsg]);


    // GPDR
    const togglePersist = () => {
        setPersist(prev => !prev);
    }


    // SUBMIT
    const handleCreateAccount = (e) => {
        e.preventDefault();
        props.handleView('register');
    }
    const handleSubmit = async e => {
        e.preventDefault();

        console.log("check Fields");
        console.log('username regex:', regex(userName, 'email'));
        console.log('username regex:', regex(userPassword, 'password'));
        //*** prevent JS hack
        if ( !regex(userName, 'email') || !regex(userPassword, 'password') ) {
            console.log("check invalid");
            setErrMsg("Please check login credentials");
            return
        }

        try {
            const response = await axios.post(BASE_URL + '/shop/authentication-token',
                JSON.stringify({"email": userName, "password": userPassword}),
                {
                    headers: {'Content-Type': 'application/json'},
                    // withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
            const token = response?.data?.token;
            const refresh_token = response?.data?.refresh_token;
            const customer = response?.data?.customer;
            //set in the global context
            setAuth({username: userName, userPassword, token, refresh_token, customerUrl: customer});
            setUser({customerUrl: customer});


            // GTM EVENT : connexion
            handleConnexionEvent();

            // removing data storage
            cleanUpLocalStorage();

            navigate(from, {replace: true});

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No server responses');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password')
            } else if (err.response?.status === 401) {
                setErrMsg('invalid identifiers [Unauthorized]')
            } else {
                setErrMsg('Login failed');
            }
        }
    }


    // PASSWORD RECOVERY
    const checkIdenticalUserNames = () => {
        return userName === userNameConfirmation
    }
    const handlePasswordRecovery = () => {
        //check user fields
        if (!checkIdenticalUserNames()) {
            setErrMsg('User names are not identical')
            return
        }

        let isEmailExist = true;

        (async () => {

            logInConsole("before check", "black");
            await axios.post(BASE_URL + '/shop/user/check-email', JSON.stringify({"email": userName}), {
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    logInConsole('response', response.data.emailExists);
                    if (response.data.emailExists) {
                        logInConsole('Email already exists in db', 'orange');
                    } else {
                        setErrMsg('This email does not exist');
                        isEmailExist = false;
                        logInConsole('Email does not exist in db', 'green');
                    }
                })
                .catch(error => {
                    logInConsole(`Error ${error.response.status}: check error`, 'red');
                    // setErrorBackendConnection(true);
                    ErrorHandling(null, error.response.data);
                })
                .finally(() => {
                    // setConnectToBackend(false);
                });


            if (isEmailExist) {
                logInConsole("before if", "black");
                await axios.post(BASE_URL + "/shop/reset-password-requests",
                    JSON.stringify({"email": userName}), {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        }
                    })
                    .then(
                        (response) => {
                            logInConsole(`Data sent successfully`, 'forestgreen');
                            setRecoveryMailSent(true);
                        },
                        (error) => {
                            const resMessage = (error.response && error.response.data && error.response.data.message) ||
                                error.message || error.toString();
                            console.log("---> ", resMessage);
                            console.log('error when sending recovery data');
                        }
                    )
                    .finally(() => {
                        // setConnectToBackend(false);
                    });
            }

        })();

    }


    return (

        <section className="login-part">
            <Typography variant={"h1"}>
                {!passwordRecoveryMode && !isFirstConnection && t("common.login")}
                {passwordRecoveryMode && t("login.passwordRecovery")}
                {isFirstConnection && t("login.firstConnection")}
            </Typography>
            <Typography component={"span"}>
                {isFirstConnection ?
                    t("login.firstConnectionDescription")
                    :
                    t("login.enterOurEshop")
                }
            </Typography>

            <Box className={showErrMsg && "errorMessage"} sx={showErrMsg ? {margin: "30px 0 0 0"} : {}}>
                {showErrMsg ? <ErrorMessage message={errMsg} /> : ''}
            </Box>

            {passwordRecoveryMode || isFirstConnection ?
                <Box className="form-wrapper">

                    {recoveryMailSent ?
                        <Box>An email has been sent to your address mail to recover your password.</Box>
                        :
                        <>
                            <TextField
                                id="userName"
                                label={t("common.email")}
                                onChange={e => setUserName(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                required
                                onKeyDown={e=>(e.key === 'Enter' ? handlePasswordRecovery() : null)}
                            />
                            <TextField
                                id="userNameConfirmation"
                                label={t("common.emailConfirmation")}
                                onChange={e => setUserNameConfirmation(e.target.value)}
                                variant="outlined"
                                margin="normal"
                                required
                                onKeyDown={e=>(e.key === 'Enter' ? handlePasswordRecovery() : null)}
                            />
                        </>
                    }
                    <Box className="login-cta">
                        {recoveryMailSent ?
                            <ButtonIcape
                                onClick={() => {
                                    setPasswordRecoveryMode(false);
                                    setIsFirstConnection(false);
                                    setRecoveryMailSent(false);
                                }}
                            >
                                {t("common.login")}
                            </ButtonIcape>
                            :
                            <ButtonIcape
                                onClick={handlePasswordRecovery}
                                endIcon={passwordRecoveryMode && <RestartAltIcon/>}
                            >
                                {passwordRecoveryMode && t("cta.recover")}
                                {isFirstConnection && t("cta.newPassword")}
                            </ButtonIcape>
                        }

                    </Box>
                </Box>
                :
                <form onSubmit={handleSubmit} className="form-wrapper">
                    <TextField
                        id="username"
                        label={t("common.email")}
                        onChange={e => setUserName(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        onKeyDown={e=>(e.key === 'Enter' ? handleSubmit(e) : null)}
                    />
                    <TextField
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        label={t("common.password")}
                        onChange={e => setPassword(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        onKeyDown={e=>(e.key === 'Enter' ? handleSubmit(e) : null)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>  setShowPassword(!showPassword)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />

                    <Box className="login-cta">
                        <ButtonIcape
                            // TODO Error to resolve on loading
                            //loading={loading}
                            onClick={handleSubmit}
                            endIcon={<LockOpen/>}
                        >
                            {t("common.login")}
                        </ButtonIcape>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    color="primary"
                                    size="small"
                                    onChange={togglePersist}
                                    checked={persist}/>}
                                label={t("common.rememberMe")}
                                size="small"/>
                        </FormGroup>
                    </Box>
                </form>
            }

            <Box className="redirectionLinkContainer">
                <Box className="redirectionLink">
                    {t("login.notRegisterYet")}
                    <span onClick={handleCreateAccount}>
                        {t("login.createAnAccount")}
                    </span>
                </Box>
                {passwordRecoveryMode || isFirstConnection ?
                    <Box className="redirectionLink">
                        {t('register.alreadyHaveAnAccount')}
                        <span onClick={() => {
                            setPasswordRecoveryMode(false);
                            setIsFirstConnection(false);
                        }}>
                            {t("common.login")}
                        </span>
                    </Box>
                    :
                    <Box className="redirectionLink">
                        <span onClick={() => {
                            setPasswordRecoveryMode(true);
                            setIsFirstConnection(false);
                        }}>
                            {t("login.forgottenPassword")}
                        </span>
                    </Box>
                }
            </Box>
        </section>
    )
}

export default Login;
