import React, {useContext, useEffect, useState} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, styled, Typography} from "@mui/material";
import './step-3.scss';
import {Info} from "@mui/icons-material";
import CalculatorFlexContext from "../../../../context/calculatorFlexProvider";
import {logInConsole} from "../../../../tools/debug";
import PropTypes from 'prop-types';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DateCalendar, LocalizationProvider} from '@mui/x-date-pickers';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import {
    addDays,
    differenceInBusinessDays,
    differenceInDays,
    format,
    isBefore,
    isValid,
    isWeekend,
    nextMonday,
    parseISO,
    subBusinessDays
} from "date-fns";
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import StencilFields from "../../components/stencil-fields";
import CommentField from "../../components/comment-field";
import {isHoliday} from "../../../../tools/daysOff";

//SETTING COLORS FOR THE CALENDAR
const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay' && prop !== 'isShippingDay' && prop !== 'isWeekEnd' && prop !== 'isSelected'
})(({theme, day, dayIsBetween, isFirstDay, isLastDay, isShippingDay, isWeekEnd, isSelected}) => ({
    ...(day && {
        width: '40px'
    }),
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: '#007DC8',
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        backgroundColor: '#007DC8',
        color: theme.palette.common.white,
        border: 'none !important',
        borderColor: 'white !important'
    }),
    ...(isLastDay && {
        backgroundColor: '#00588C',
        borderTopRightRadius: '0%',
        borderBottomRightRadius: '0%',
    }),
    ...(isShippingDay && {
        backgroundColor: '#0d4a73',
        color: theme.palette.common.white
    }),
    ...(isWeekEnd && dayIsBetween && {
        backgroundColor: '#E6F2F8',
        border: 'none'
    }),
    ...(isSelected && {
        backgroundColor: '#BAE19F !important',
        color: '#353535 !important',
        borderTopLeftRadius: '0%',
        borderBottomLeftRadius: '0%',
    }),
    ...(isHoliday(day) && {
        backgroundColor: '#80475E',
        color: '#FFFFFF'
    }),
}));

function Step3(props) {
    const {t} = useTranslation();
    const {availableCountries, muiLocale} = useContext(UserContext);

    const updateRepeatOrder = props.updateRepeatOrder

    const {
        repeatOrderMode, isLockFields,
        shippingMethodId, setShippingMethodId,
        country, setCountry,
        requestedDate, setRequestedDate,
        minDeliveryDate,
        daysToDelivery, productionTime,
        totalLeadTime, setTotalLeadTime,
        isEconomyEligible, setIsEconomyEligible,
        unitFormat,
        comment, setComment
    } = useContext(CalculatorFlexContext);

    const HOUR_LIMIT = 17;//maximum hour for past an order
    const EXPRESS_SHIPPING_DAYS = 4;
    const ECONOMY_SHIPPING_DAYS = 5;

    let differenceInBusDays = 0;
    const getRequestDate = (_requestedDate, _minDeliveryDate) => {
        //calendar will return a classic date format. but in JSON, it's an ISO format.
        //So to manage the first component load AND the click on a new date, the code above is needed.
        if (!isValid(_requestedDate)) {
            if (!isValid(parseISO(_requestedDate))) {
                _requestedDate = new Date();
            } else {
                _requestedDate = parseISO(_requestedDate);
            }
        }
        let requestedDateCalculated = _requestedDate;
        // logInConsole(`requested date calculated : ${requestedDateCalculated}`, 'yellow');
        //req date < date min
        if (isBefore(requestedDateCalculated, parseISO(_minDeliveryDate)))
        {
            requestedDateCalculated = parseISO(_minDeliveryDate);
        }
        // logInConsole(`so returns ${requestedDateCalculated}`, 'orange');
        return requestedDateCalculated;
    }
    const [deliveryDate, setDeliveryDate] = useState(getRequestDate(requestedDate, minDeliveryDate));
    let dateMinAvailable = minDeliveryDate ? parseISO(minDeliveryDate) : getRequestDate(requestedDate, minDeliveryDate);


    /**
     * 4 shipping days if Express (id 2) - 5 shipping days if economy (id 1)
     * @returns number of shipping days
     */
    const getNumberOfShippingDays = () => {

        let shippingStart = subBusinessDays(deliveryDate, daysToDelivery);
        let holidaysCount = 0;

        while (shippingStart <= deliveryDate) {
            if (isHoliday(shippingStart)) {
                holidaysCount++;
            }
            shippingStart.setDate(shippingStart.getDate() + 1);
        }

        return daysToDelivery + holidaysCount;
    }
    const numberOfShippingDays = getNumberOfShippingDays(shippingMethodId);

    //to avoid refresh state error, need to set working days after the refresh of the step3 component
    useEffect(() => {
        if ( (differenceInBusDays + 1 - numberOfShippingDays) + numberOfShippingDays >= 15) {
            setIsEconomyEligible(true);
        }else {
            if (shippingMethodId === 1) {
                setShippingMethodId(2);
            }
            setIsEconomyEligible(false);
        }
    })
    useEffect(() => {
        logInConsole(`min delivery date has change : ${minDeliveryDate} (req:${requestedDate})`, 'olive');
        if (minDeliveryDate === '' || minDeliveryDate === null || requestedDate === '' || requestedDate === null) {
            return
        }
        if (new Date(requestedDate) < new Date(parseISO(minDeliveryDate))) {
            handleRequestedDate(parseISO(minDeliveryDate));
        }
    }, [minDeliveryDate])


    const handleRequestedDate = (date) => {
        if (date === null || !isValid(date)) {
            return
        }
        date.setHours(9);
        setDeliveryDate(date);
        setRequestedDate(date);
    }
    function renderDay(props) {

        const {day, selectedDay, ...other} = props;

        if (!selectedDay) {
            return <PickersDay day={day}{...other} />;
        }

        const today = new Date();
        let start;
        if(isWeekend(today))
        {
            start = nextMonday(today);
        }else {
            start = today;
        }

        const end = addDays(start, differenceInDays(deliveryDate, start));
        differenceInBusDays = differenceInBusinessDays(new Date(deliveryDate), start);



        const dayIsBetween = isWithinInterval(day, {start, end});
        const isFirstDay = isSameDay(day, start);
        const isLastDay = isSameDay(day, end);
        const isWeekEnd = isWeekend(day);
        const isSelected = isSameDay(day, selectedDay);

        const isShippingDay = isWithinInterval(day, {
            start: subBusinessDays(deliveryDate, numberOfShippingDays),
            end: deliveryDate
        })
        return (
            <CustomPickersDay
                {...other}
                day={day}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
                isShippingDay={isShippingDay}
                isWeekEnd={isWeekEnd}
                isSelected={isSelected}
            />
        );
    }
    renderDay.propTypes = {
        /**
         * The date to show.
         */
        day: PropTypes.object.isRequired,
        selectedDay: PropTypes.object,
    };
    // Disable week ends and all dates before the minimum delivery date.
    function disableDates(date) {
        return date.getDay() === 0 || date.getDay() === 6 || date < dateMinAvailable || isHoliday(date);
    }
    const formatDate = (date) => {
        return format(new Date(date), 'PPPP', { locale: muiLocale });
    }


    /**
     * Find and set the country from its id
     * Mui can't compare an object with the coutries list result. so it's work when selected but not at boot
     * this function avoid an empty field at boot even if the country useState is set
     * @param _countrySelectedId
     */
    const handleCountryChosen = (_countrySelectedId) => {
        for(let i in availableCountries) {
            if (availableCountries[i].id === _countrySelectedId) {
                setCountry(availableCountries[i]);
            }
        }
    }


    return (
        <>
            <Box className="technical-data-card-column step-3-options">
                {/*STENCIL*/}
                <StencilFields
                    fields={{}}
                    isStencil = {false}
                    isActive = {true}
                    repeatOrder={{}} />


                {/*COMMENT*/}
                <CommentField
                    fields={{comment, setComment, isLockFields}}
                    isActive = {true} />
            </Box>

            {/*DELIVERY*/}
            <Box className="technical-data-card delivery-form-settings">
                <Typography variant={'h3'}>
                    {t("common.delivery")}
                </Typography>
                <Typography component={'span'} className="calendar-helper-info">
                    {t("calculators.step3.calendar.chooseDeliveryDate")}
                </Typography>
                <Box className={"delivery-picker-wrapper"}>
                    {/*DELIVERY FIELDS*/}
                    <Box className="delivery-selector-info">
                        <Box className={"delivery-box-fields"}>

                            {/*TOTAL LEAD TIME*/}
                            {/*
                            <FormControl>
                                <InputLabel id="totalLeadTime-label">Total lead time</InputLabel>
                                <Select
                                    labelId="totalLeadTime"
                                    id="totalLeadTime"
                                    value={totalLeadTime}
                                    label="Total lead time"
                                    onChange={(e) => setTotalLeadTime(e.target.value)}
                                    disabled={true}
                                >
                                    <MenuItem value={1}>5 WD</MenuItem>
                                    <MenuItem value={2}>6 WD</MenuItem>
                                    <MenuItem value={3}>7 WD</MenuItem>
                                    <MenuItem value={4}>8 WD</MenuItem>
                                    <MenuItem value={5}>9 WD</MenuItem>
                                    <MenuItem value={6}>10 WD</MenuItem>
                                    <MenuItem value={7}>11 WD</MenuItem>
                                    <MenuItem value={8}>12 WD</MenuItem>
                                    <MenuItem value={9}>13 WD</MenuItem>
                                    <MenuItem value={10}>14 WD</MenuItem>
                                    <MenuItem value={11}>15 WD</MenuItem>
                                    <MenuItem value={12}>Calendar selection</MenuItem>
                                </Select>
                            </FormControl>
                            */}

                            {/*SHIPPING METHOD*/}
                            <FormControl>
                                <InputLabel id="shippingMethod-label">
                                    {t("calculators.step3.fields.shippingMethod")}
                                </InputLabel>
                                <Select
                                    labelId="shippingMethod"
                                    id="shippingMethod"
                                    value={shippingMethodId}
                                    label={t("calculators.step3.fields.shippingMethod")}
                                    onChange={(e) => {
                                        setShippingMethodId(e.target.value);
                                        updateRepeatOrder(); }}>
                                    {isEconomyEligible && <MenuItem value={1}>{t("calculators.selectable.economy")}</MenuItem>}
                                    <MenuItem value={2}>{t("calculators.selectable.express")}</MenuItem>
                                </Select>
                            </FormControl>

                            {/*DELIVERY COUNTRY*/}
                            <FormControl>
                                <InputLabel id="deliveryCountry-label">
                                    {t("calculators.step3.fields.deliveryCountry")}
                                </InputLabel>
                                <Select
                                    labelId="deliveryCountry"
                                    id="deliveryCountry"
                                    value={country.id}
                                    label={t("calculators.step3.fields.deliveryCountry")}
                                    onChange={(e) => {
                                        handleCountryChosen(e.target.value)
                                        updateRepeatOrder(); }}>
                                    {availableCountries.map((_country, i) => {
                                        //return <MenuItem name="pays" value={country.id} key={i} disabled={!country.enabled}>{country.name}</MenuItem>
                                        if (_country.enabled) {
                                            return <MenuItem name="pays"
                                                             value={_country.id}
                                                             key={i}>
                                                {_country.name}
                                            </MenuItem>
                                        }
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={"delivery-box-info"}>
                            <Box className="legend-line">
                                <Box className="legend-square production-time"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.productionTime")} :
                                    <span className="working-days-legend">
                                        {productionTime}
                                    </span>
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <Box className="legend-square days-to-delivery"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.daysToDelivery")} :
                                    <span className="shipping-days-legend">
                                        {daysToDelivery}
                                    </span>
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <Box className="legend-square daysOff"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.publicHolidays")}
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <Box className="legend-square estimated-delivery-date"></Box>
                                <Typography component={"span"}>
                                    {t("calculators.step3.calendar.estimatedDeliveryDate")} :
                                </Typography>
                            </Box>
                            <Box className="legend-line">
                                <span
                                    className="delivery-date-legend"> {deliveryDate ? formatDate(deliveryDate).toString() : ''}
                                </span>
                            </Box>
                        </Box>
                    </Box>

                    {/*NEW ONE*/}
                    <LocalizationProvider adapterLocale={muiLocale} dateAdapter={AdapterDateFns}>
                        <DateCalendar
                            onChange={(newDeliveryDate) => {
                                handleRequestedDate(newDeliveryDate);
                                updateRepeatOrder();
                            }}
                            shouldDisableDate={disableDates}
                            value={deliveryDate}
                            slots={{day: renderDay}}
                            slotProps={{
                                day: {
                                    selectedDay: deliveryDate,
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box className='customer-info'>
                    <Info/>
                    <span>{t("calculators.step3.calendar.deliveryInfo")}</span>
                </Box>
            </Box>
        </>
    )
}

export default Step3;