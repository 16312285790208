import {
    Box, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter
} from "@mui/x-data-grid";
import CardManager from "../../../calculators/components/repeat-order/card-manager";
import {CUSTOMER_ORDERS, CUSTOMER_REFERENCES, getData} from "../../../../services/customer.service";
import React, {useContext, useEffect, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import BackendConnection from "../../../backend-connection/backend-connection";
import {logInConsole, logInConsoleObject, logInConsoleSpacer} from "../../../../tools/debug";
import {axiosPrivate} from "../../../../api/axios";
import UserOrderDetails from "./user-order-details";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import {useNavigate} from "react-router-dom";
import {ButtonIcapeOutlined, ButtonIcapeSoftBlue} from "../../../button/button-icape";
import CalculatorAluminumContext from "../../../../context/calculatorAluminumProvider";
import CalculatorFlexContext from "../../../../context/calculatorFlexProvider";
import CalculatorHdiContext from "../../../../context/calculatorHdiProvider";
import CalculatorRigidContext from "../../../../context/calculatorRigidProvider";
import {handleClickOrderEvent} from "../../../../tools/google-tag-manager-events";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import DoNotDisturbOnTwoToneIcon from '@mui/icons-material/DoNotDisturbOnTwoTone';
import {ErrorHandling} from "../../../../tools/error-handling";
import RemoveShoppingCartTwoToneIcon from "@mui/icons-material/RemoveShoppingCartTwoTone";

const UserQuotes = (props) => {
    const{t} = useTranslation();
    const {auth} = useAuth();
    const [connectToBackend, setConnectToBackend] = useState(false);
    const {setErrorBackendConnection} = props;
    const tabView = true;

    const { loadCustomerReferenceAluminum } = useContext(CalculatorAluminumContext);
    const { loadCustomerReferenceFlex } = useContext(CalculatorFlexContext);
    const { loadCustomerReferenceHdi } = useContext(CalculatorHdiContext);
    const { loadCustomerReferenceRigid } = useContext(CalculatorRigidContext);
    const navigate = useNavigate()

    const {dataGridLocale} = useContext(UserContext);
    const [dataGridHeight, setDataGridHeight] = useState(694);

    const [customerReferences, setCustomerReferences] = useState([]);

    const [isPcbDialogOpen, setIsPcbDialogOpen] = useState(false);
    const [ineligibleQuote, setIneligibleQuote] = useState(false);
    const [ineligibleQuoteData, setIneligibleQuoteData] = useState({})
    const [isStencilOrderDialog, setIsStencilOrderDialog] = useState(false);
    const [pcbDetails, setPcbDetails] = useState({})

    const isStencilEnabled = true;

    const columns = [
        {field: 'pcbPartNumber', headerName: t("account.details.partNumber"), flex: 3},
        {field: 'type&technology', headerName: t("common.product"), flex: 2},
        {field: 'createdAt', headerName: t("account.details.createdAt"), flex: 1},
        {field: 'pcbQuantity', headerName: t("common.quantity"), type: 'number', flex: 1},
        {
            field: 'prices',headerName: t("common.total"),type: 'number',flex: 1,
            valueGetter: (params) => {
                const { value, row } = params;
                return `${value.fullPrice} ${row.currency.symbol}`;
            },
        },
        {field: 'actions', type: 'actions', flex: 1, sortable: false, align: 'center',
            renderCell: (params) => (
                <Box sx={{display: "flex"}}>
                    <Tooltip title={t("common.pcb")}>
                        <IconButton className="tooltip-icon-blue"
                            onClick={(event) => {
                            event?.stopPropagation();
                                handleShowPcbDetails(params.row.id);
                        }} >
                            <InfoTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                    {!isStencilEnabled && params.row.isStencil ?
                        <>
                            {/*Stencil unavailable*/}
                            <Tooltip title={t("cta.unavailable")}>
                                <IconButton className={"tooltip-icon-disabled"}
                                            onClick={(event) => {
                                                event?.stopPropagation();
                                                setIsStencilOrderDialog(true);
                                            }}>
                                    <DoNotDisturbOnTwoToneIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                        :
                        <>
                            {params.row.isOrdered || !params.row.isValid ?
                                // Quote ordered or outdated
                                <Tooltip title={params.row.isOrdered ? t("cta.ordered") : t("cta.outdated")}>
                                    <IconButton className={"tooltip-icon-disabled"}
                                                onClick={(event) => {
                                                    event?.stopPropagation();
                                                    setIneligibleQuoteData({
                                                        isOrdered : params.row.isOrdered,
                                                        isValid : params.row.isValid
                                                    })
                                                    setIneligibleQuote(true);
                                                }}>
                                        <RemoveShoppingCartTwoToneIcon />
                                    </IconButton>
                                </Tooltip>
                                :
                                <>
                                    {/*edit order*/}
                                    <Tooltip title={t("cta.update")}>
                                        <IconButton className={"tooltip-icon-blue"}
                                                    onClick={(event) => {
                                                        event?.stopPropagation();
                                                        handleQuoteActions(params.row.id, false)
                                                    }}>
                                            <EditTwoToneIcon />
                                        </IconButton>
                                    </Tooltip>

                                    {/*Order NOW*/}
                                    <Tooltip title={t("cta.orderNow")}>
                                        <IconButton className={"tooltip-icon-blue"}
                                                    onClick={(event) => {
                                                        event?.stopPropagation();
                                                        handleQuoteActions(params.row.id, true)
                                                    }}>
                                            <ShoppingCartTwoToneIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        </>
                    }
                </Box>
            )
        },
    ];

    const [priceOfferPdf, setPriceOfferPdf] = useState('');

    //TODO create a context to have user data available at first boot
    useEffect(() => {

        let config = {headers: {"Content-Type": "application/Id+json"}};
        logInConsole(`Front syncing with DataBase : Quotes`, 'chocolate');
        (async () => {
            setConnectToBackend(true);
            try {
                const response = await getData(CUSTOMER_REFERENCES + '/customized', auth.customerId, config);

                if (response && response.length > 0) {
                    //Filter to return only quotes that are not ordered yet
                    // let customerReferencesFiltered = response.filter((reference) => {
                    //     return !reference.isOrdered;
                    // });

                    //Setting customer references with filter result
                    // setCustomerReferences(customerReferencesFiltered);
                    setCustomerReferences(response);

                    //Checking all quote received #debug
                    logInConsole(`Customer quotes received`, 'green');
                    logInConsoleObject(response);
                } else {
                    logInConsole(`No customer quotes found (empty)`, 'orange');
                }
            } catch (error) {
                const resMessage = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                logInConsole(`--->  ${resMessage}`, 'red');
                setErrorBackendConnection(true);
                ErrorHandling(null, resMessage);
            } finally {
                setConnectToBackend(false);
            }
        })();

    }, [auth.customerId])

    // UseState to handle the detail view of an order selected
    const [isReferenceSelected, setIsReferenceSelected] = useState(false);
    const [referenceSelected, setReferenceSelected] = useState('');

    // HANDLING ORDER SELECTED
    const handleQuoteSelected = (_idSelected) => {
        setConnectToBackend(true);

        logInConsole(`Quote selected : [${_idSelected}]`, 'cyan');

        logInConsole(`Asking Backend quote`, 'yellow');
        axiosPrivate.get(`${process.env.REACT_APP_BACKEND_API_BASE_URL}/shop/price-offers/` + _idSelected + '/customized')
            .then(
                (response) => {
                    logInConsole(`Quote sent by backend :`, 'chocolate');
                    logInConsoleObject(response.data);

                    setReferenceSelected(response.data);
                    setConnectToBackend(false);
                    setIsReferenceSelected(true)
                    setPriceOfferPdf(response.data.priceOfferPdf);

                    logInConsole(`Quote pdf :`, 'burlywood');
                    logInConsoleObject(response.data.priceOfferPdf);
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsole(`--->  ${resMessage}`, 'red');
                    setErrorBackendConnection(true);
                    ErrorHandling(null, resMessage);
                }
            ).finally(
            () => {
                setConnectToBackend(false);
                window.scrollTo(0,0);
            }
        )
    }

    /**
     * Requesting price offer data to load ir or order it
     * @param {int} _quoteId
     * @param {boolean} isCart
     */
    const handleQuoteActions = (_quoteId, isCart) => {

        logInConsole(`Quote id to order : [${_quoteId}]`, 'cyan');

        (async () => {
            try {
                setConnectToBackend(true);
                const response = await axiosPrivate.get('/shop/' + _quoteId + '/price-offer-to-order')

                logInConsole(`Quote details sent from backend :`, 'chocolate');
                logInConsoleObject(response.data);

                window.scrollTo({ top: 0, behavior: 'smooth' });

                if (response.data.pcb.pcbType === 'Aluminium') {
                    loadCustomerReferenceAluminum(response.data, false, isCart);
                    navigate("/aluminum");
                }
                if (response.data.pcb.pcbType === 'Flex') {
                    loadCustomerReferenceFlex(response.data, false, isCart);
                    navigate("/flex");
                }
                if (response.data.pcb.pcbType === 'Hdi') {
                    loadCustomerReferenceHdi(response.data, false, isCart);
                    navigate("/hdi");
                }
                if (response.data.pcbType === 'Rigid') {
                    loadCustomerReferenceRigid(response.data, false, isCart);
                    navigate("/rigid");
                }

                // GTM EVENT : click_order_now
                handleClickOrderEvent(
                    'click_order_now',
                    response.data.customPanelization,
                    response.data.pcb.pcbType,
                );


            } catch (error) {
                const resMessage = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                logInConsole(`--->  ${resMessage}`, 'red');
                setErrorBackendConnection(true);
                ErrorHandling(null, resMessage);
            } finally {
                setConnectToBackend(false);
            }
        })();


        // Avoid memory leak with fetching data
        let unmounted = false;

        return () => {
            unmounted = true;
        };
    };

    /**
     * Requesting PCB details from a price offer.
     * Displays it in dialog.
     * @param {int} _quoteId
     */
    const handleShowPcbDetails = (_quoteId) => {
        logInConsoleSpacer();
        logInConsole(`Try to GET quote details [Quote ID : ${_quoteId}]`, 'darkmagenta', 'bold');

        (async () => {
            try {
                setConnectToBackend(true);
                const response = await axiosPrivate.get('/shop/pcb-details-from-price-offer/' + _quoteId)

                logInConsole(`Quote details received from backend :`, 'green');
                logInConsoleObject(response.data);

                setPcbDetails(response.data);
                setIsPcbDialogOpen(true);

            } catch (error) {
                const resMessage = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                logInConsole(`--->  ${resMessage}`, 'red');
                setErrorBackendConnection(true);
                ErrorHandling(null, resMessage);
            } finally {
                setConnectToBackend(false);
            }
        })();
    }

    const pcbDetailsStencil = (
        <>
            <Typography variant="h3">{t(`common.stencil`)}</Typography>
            <table>
                <tbody>
                {pcbDetails.stencil && Object.entries(pcbDetails.stencil).map(([key, value], index) => (
                    <tr key={index}>
                        <td>{key === "type" ? t(`common.stencil`) : t(`calculators.step3.fields.${key}`)}</td>
                        <td>{value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )

    const pcbDetailsPanel = (
        <>
            <Typography variant="h3">{t(`common.panel`)}</Typography>
            <table>
                <tbody>
                {pcbDetails.panel && Object.entries(pcbDetails.panel).map(([key, value], index) => (
                    <tr key={index}>
                        <td>{t(`calculators.step1.fields.${key}`)}</td>
                        <td>{value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )

    return (
        <>
            {/*LOADING BAR BACKEND*/}
            { connectToBackend ? <BackendConnection/> : null}

            <Box className="user-content-field-wrapper">

                {
                    isReferenceSelected ?
                        <UserOrderDetails
                            priceOfferPdf = {priceOfferPdf}
                            referenceSelected={referenceSelected}
                            setIsSelected={setIsReferenceSelected}
                            handleOrderNowInfos={handleQuoteActions}
                        />
                        :
                        <>
                            <Typography variant={"h3"}>
                                {t("account.quotes.title")}
                            </Typography>

                            {tabView ?
                                <Box sx={{height: dataGridHeight,  width: '100%'}}>
                                    <DataGrid
                                        rows={customerReferences}
                                        columns={columns}
                                        slots={{ toolbar: () => {
                                            return <GridToolbarContainer style={{justifyContent: 'flex-end', gap: '30px'}}>
                                                <GridToolbarFilterButton />
                                                <GridToolbarQuickFilter />
                                            </GridToolbarContainer>
                                        }}}
                                        disableColumnMenu
                                        initialState={{
                                            pagination: { paginationModel: { pageSize: 10 } },
                                            sorting: {sortModel: [{ field: 'createdAt', sort: 'desc' }], },
                                        }}
                                        onPaginationModelChange={(params) => {
                                            window.scrollTo(0, 0);
                                            if(params.pageSize === 5){
                                                setDataGridHeight(450);
                                                return
                                            }
                                            setDataGridHeight(params.pageSize * 52 + 147 + 25);
                                        }}
                                        pageSizeOptions={[5, 10, 25, 50]}
                                        localeText={dataGridLocale}
                                        onRowClick={element=> {
                                            if (!isStencilEnabled && element.row.isStencil) {
                                                setIsStencilOrderDialog(true);
                                            } else {
                                                handleQuoteSelected(element.row.id)
                                            }
                                        }}
                                    />
                                </Box>
                                :
                                <CardManager data={customerReferences}/>
                            }
                        </>
                }
            </Box>

            {/*POPUP PCB DETAILS*/}
            <Dialog open={isPcbDialogOpen} onClose={ () => setIsPcbDialogOpen(false)}
                    fullWidth={true} maxWidth={window.innerWidth > 1200 ? "lg" : "sm"}>
                <DialogTitle>
                    {t("common.pcb")}
                </DialogTitle>
                <DialogContent>
                    <Box className="table-pcb-details-wrapper">

                        {/* KEY DATA */}
                        <Box className="table-pcb-details">
                            <Typography variant="h3">Key data</Typography>
                            <table>
                                <tbody>

                                {pcbDetails.type &&
                                    <tr>
                                        <td>{t(`account.details.technology`)}</td>
                                        <td>{t(`calculators.${pcbDetails.type}.name`)}</td>
                                    </tr>
                                }
                                
                                {pcbDetails.keyData && Object.entries(pcbDetails.keyData).map(([key, value], index) => (
                                    <tr key={index}>
                                        <td>{t(`calculators.step1.fields.${key}`)}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </Box>

                        {/* TECHNOLOGY */}
                        <Box className="table-pcb-details">
                            <Typography variant="h3">{t(`calculators.step2.fields.technology.name`)}</Typography>
                            <table>
                                <tbody>
                                {pcbDetails.technology && Object.entries(pcbDetails.technology).map(([key, value], index) => (
                                    <tr key={index}>
                                        <td>{t(`calculators.step2.fields.technology.${key}`)}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Box>

                        {/* MATERIAL & PCB DETAILS */}
                        <Box className="table-pcb-details">
                            {/* MATERIAL */}
                            <Typography variant="h3">{t(`calculators.step2.fields.material.name`)}</Typography>
                            <table>
                                <tbody>
                                {pcbDetails.material && Object.entries(pcbDetails.material).map(([key, value], index) => (
                                    <tr key={index}>
                                        <td>{t(`calculators.step2.fields.material.${key}`)}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            {/* PCB DETAILS */}
                            <Typography variant="h3" sx={{marginTop: "20px"}}>{t(`calculators.step2.fields.pcbDetails.name`)}</Typography>
                            <table>
                                <tbody>
                                {pcbDetails.pcbDetails && Object.entries(pcbDetails.pcbDetails).map(([key, value], index) => (
                                    <tr key={index}>
                                        <td>{t(`calculators.step2.fields.pcbDetails.${key}`)}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                    {pcbDetails.type !== "flex" &&
                        <Box className="table-pcb-details-wrapper">

                            {/* ADVANCE OPTIONS */}
                            <Box className="table-pcb-details" sx={ !pcbDetails.stencil && !pcbDetails.panel && { borderRight: "none"}}>
                                <Typography variant="h3">{t(`calculators.step2.fields.advancedOption.name`)}</Typography>
                                <table>
                                    <tbody>
                                    {pcbDetails.advancedOption && Object.entries(pcbDetails.advancedOption).map(([key, value], index) => (
                                        <tr key={index}>
                                            <td>{t(`calculators.step2.fields.advancedOption.${key}`)}</td>
                                            <td>{value}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Box>

                            {/* STENCIL & PANEL - For good display */}
                            {pcbDetails.stencil && pcbDetails.panel &&
                                <>
                                    {/* STENCIL */}
                                    <Box className="table-pcb-details">
                                        {pcbDetailsStencil}
                                    </Box>

                                    {/* PANEL */}
                                    <Box className="table-pcb-details">
                                        {pcbDetailsPanel}
                                    </Box>
                                </>
                            }

                            {!pcbDetails.stencil && pcbDetails.panel &&
                                <>
                                    {/* PANEL */}
                                    <Box className="table-pcb-details" sx={{borderRight: "none"}}>
                                        {pcbDetailsPanel}
                                    </Box>

                                    {/* SPACER */}
                                    <Box className="table-pcb-details" sx={{borderRight: "none"}}></Box>
                                </>
                            }

                            {pcbDetails.stencil && !pcbDetails.panel &&
                                <>
                                    {/* STENCIL */}
                                    <Box className="table-pcb-details" sx={{borderRight: "none"}}>
                                        {pcbDetailsStencil}
                                    </Box>

                                    {/* SPACER */}
                                    <Box className="table-pcb-details" sx={{borderRight: "none"}}></Box>
                                </>
                            }

                            {!pcbDetails.stencil && !pcbDetails.panel &&
                                <>
                                    {/* SPACER */}
                                    <Box className="table-pcb-details" sx={{borderRight: "none"}}></Box>

                                    {/* SPACER */}
                                    <Box className="table-pcb-details" sx={{borderRight: "none"}}></Box>
                                </>
                            }

                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeSoftBlue onClick={() => setIsPcbDialogOpen(false)}>
                        {t("cta.close")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>

            {/*POPUP QUOTATION ALREADY ORDERED OR NOT VALID*/}
            <Dialog open={ineligibleQuote} onClose={() => setIneligibleQuote(false)}>
                <DialogTitle>
                    {ineligibleQuoteData.isOrdered ? t("popup.ineligibleQuote.titleOrdered") : t("popup.ineligibleQuote.titleOutdated")}
                </DialogTitle>
                <DialogContent>
                    {ineligibleQuoteData.isOrdered ? t("popup.ineligibleQuote.contentOrdered") : t("popup.ineligibleQuote.contentOutdated")}
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeSoftBlue  onClick={ () => setIneligibleQuote(false) }>
                        {t("cta.close")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>

            {/*POPUP STENCIL ORDER NOT AVAILABLE*/}
            <Dialog open={isStencilOrderDialog} onClose={ () => setIsStencilOrderDialog(false) }>
                <DialogTitle>
                    {t("popup.stencilUnavailable.titleQuote")}
                </DialogTitle>
                <DialogContent>
                    {t("popup.stencilUnavailable.content1")} <br/>
                    {t("popup.stencilUnavailable.content2")} <br/>
                    {t("popup.stencilUnavailable.content3")}
                </DialogContent>
                <DialogActions>
                    <ButtonIcapeSoftBlue  onClick={ () => setIsStencilOrderDialog(false) }>
                        {t("cta.close")}
                    </ButtonIcapeSoftBlue>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UserQuotes;
