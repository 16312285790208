import {Box, Divider, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {ButtonIcape, ButtonIcapeGreen, ButtonIcapeOutlined} from "../../../button/button-icape";
import React, {useContext, useEffect, useState} from "react";
import {CUSTOMER_INFOS, CUSTOMER_PSWD, getData} from "../../../../services/customer.service";
import useAuth from "../../../../hooks/useAuth";
import {axiosPrivate} from "../../../../api/axios";
import BackendConnection from "../../../backend-connection/backend-connection";
import {logInConsole, logInConsoleObject} from "../../../../tools/debug";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import {regex} from "../../../../tools/regex";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import {useNavigate} from "react-router-dom";
import {ErrorHandling} from "../../../../tools/error-handling";

const UserAccountSettings = (props) => {
    const {t} = useTranslation();

    // USER FIELD REACT STATES
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);

    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const [isEmailValid, setIsEmailValid] = useState(true);

    const [isEditable, setIsEditable] = useState(false);
    const [userInfoTempo, setUserInfoTempo] = useState({});
    const [userMailPrefTempo, setUserMailPrefTempo] = useState({});

    const {
        auth,
        connectToBackend, setConnectToBackend,
        errorBackendConnection, setErrorBackendConnection,
        customerInfo, setCustomerInfo,
        isVerified,
        company, setCompany,
        firstName, setFirstName,
        lastName, setLastName,
        phoneNumber, setPhoneNumber,
        email, setEmail,
        newPassword, setNewPassword,
        customerMailPref, setCustomerMailPref,
        technicalDocsEmail, setTechnicalDocsEmail,
        extraTechnicalDocsEmail, setExtraTechnicalDocsEmail,
        orderConfirmationEmail, setOrderConfirmationEmail,
        extraOrderConfirmationEmail, setExtraOrderConfirmationEmail,
        deliveryAndInvoiceEmail, setDeliveryAndInvoiceEmail,
        extraDeliveryAndInvoiceEmail, setExtraDeliveryAndInvoiceEmail
    } = useContext(UserContext);

    // INFO UPDATE
    const handleEdit = () => {
        // Saving data in case of cancel to display right info
        setUserInfoTempo({
            "company": company,
            "firstName": firstName,
            "lastName": lastName,
            "phone": phoneNumber,
            "email": email
        });

        setUserMailPrefTempo({
            "technicalDocsEmail" : technicalDocsEmail,
            "extraTechnicalDocsEmail" : extraTechnicalDocsEmail,
            "orderConfirmationEmail" : orderConfirmationEmail,
            "extraOrderConfirmationEmail" : extraOrderConfirmationEmail ,
            "deliveryAndInvoiceEmail" : deliveryAndInvoiceEmail,
            "extraDeliveryAndInvoiceEmail" : extraDeliveryAndInvoiceEmail,
        });

        // Unlocking fields
        setIsEditable(true);
    }
    const handleCancelEdit = () => {
        // reset field at initial data in case of modifications
        setCompany(userInfoTempo.company);
        setFirstName(userInfoTempo.firstName);
        setLastName(userInfoTempo.lastName);
        setPhoneNumber(userInfoTempo.phone);
        setEmail(userInfoTempo.email);

        setTechnicalDocsEmail(userMailPrefTempo.technicalDocsEmail)
        setExtraTechnicalDocsEmail(userMailPrefTempo.extraTechnicalDocsEmail)

        setOrderConfirmationEmail(userMailPrefTempo.orderConfirmationEmail)
        setExtraOrderConfirmationEmail(userMailPrefTempo.extraOrderConfirmationEmail)

        setDeliveryAndInvoiceEmail(userMailPrefTempo.deliveryAndInvoiceEmail)
        setExtraDeliveryAndInvoiceEmail(userMailPrefTempo.extraDeliveryAndInvoiceEmail)

        // Empty the temporary object
        setUserInfoTempo({});
        setUserMailPrefTempo({});

        // Reset phone error cause of MUI auto error check
        setIsPhoneNumberValid(false);

        // Locking fields
        setIsEditable(false);
    }
    const handleUpdatedInfo = () => {
        if (checkMail()) {
            sendUpdatedInfo();
            sendUpdatedMailPref();
        }
    }
    const checkMail = () => {
        if (regex(email, 'email')) {
            logInConsole(`Mail valid`, 'green');
            setIsEmailValid(true);
            return true
        }
        setIsEmailValid(false);
        logInConsole("Mail isn't valid", 'red');
    }
    const sendUpdatedInfo = () => {

        setConnectToBackend(true)

        let config = {headers: {"Content-Type": "application/json"}};
        let data = {
            firstName,
            lastName,
            phoneNumber,
            company,
            email
        }

        //PUT (UPDATE) ACTION
        axiosPrivate.put(CUSTOMER_INFOS.replace('ID', auth.customerId) + '/customized', data, config)
            .then(
                (response) => {
                    logInConsole('customer infos successfully updated')
                    setIsEditable(false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsole('customer infos update failed');
                    logInConsole(`--->  ${resMessage}`, 'red');
                    setErrorBackendConnection(true);
                    ErrorHandling(null, resMessage);
                }
            ).finally(
            () => {
                setConnectToBackend(false);
            }
        )
    }

    const sendUpdatedMailPref = () => {

        setConnectToBackend(true)

        let config = {headers: {"Content-Type": "application/json"}};
        let mailData = {
            technicalDocsEmail,
            extraTechnicalDocsEmail,
            orderConfirmationEmail,
            extraOrderConfirmationEmail,
            deliveryAndInvoiceEmail,
            extraDeliveryAndInvoiceEmail
        }

        //UPDATE MAIL PREFERENCES
        axiosPrivate.put(CUSTOMER_INFOS.replace('ID', auth.customerId) + '/mail-preferences', mailData, config)
            .then(
                (response) => {
                    logInConsole('customer mail preferences successfully updated')
                    setIsEditable(false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsole('customer mail preferences update failed');
                    logInConsole(`--->  ${resMessage}`, 'red');
                    setErrorBackendConnection(true);
                    ErrorHandling(null, resMessage);
                }
            ).finally(
            () => {
                setConnectToBackend(false);
            }
        )
    }

    // PASSWORD RESET
    const handlePasswordReset = () => {
        if(checkPassword()) {
            sendResetPassword();
        }
    }
    const checkPassword = () => {
        logInConsole(`password : ${newPassword}`, 'cornflowerblue');
        logInConsole(`password conf : ${confirmNewPassword}`, 'cornflowerblue');

        if (regex(newPassword, 'password') && regex(confirmNewPassword, 'password')) {
            if (newPassword === confirmNewPassword) {
                setIsPasswordValid(true);
                logInConsole(`Same password`, 'green');
                return true
            } else {
                setIsPasswordValid(false);
                logInConsole(`Different password`, 'orange');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return false
            }
        }
        setIsPasswordValid(false);
        logInConsole('Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol', 'red');
    }
    const cancelResetPassword = () => {
        setIsResetPassword(false);
        setShowPassword(false);
    }
    const sendResetPassword = () => {
        let config = {headers: {"Content-Type": "application/json"}};
        let password = {
            newPassword,
            confirmNewPassword,
            currentPassword,
        };

        //PUT (UPDATE) ACTION
        axiosPrivate.put(CUSTOMER_PSWD.replace('ID', auth.customerId), password, config)
            .then(
                (response) => {
                    logInConsole('customer password successfully modified')
                    setWrongCurrentPassword(false);
                    setIsResetPassword(false);
                    setShowPassword(false);
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    setWrongCurrentPassword(true);
                    logInConsole('customer password update failed');
                    logInConsole(`--->  ${resMessage}`, 'red');
                    setErrorBackendConnection(true);
                    ErrorHandling(null, resMessage);
                }
            ).finally(
            () => {
                setConnectToBackend(false);
            }
        )
    }

    // PHONE FIELD CHECK
    const handlePhoneNumberChange = (newPhoneNumber) => {
        setIsPhoneNumberValid(!matchIsValidTel(newPhoneNumber));
        setPhoneNumber(newPhoneNumber);
    }


    return (
        <>
            {/*LOADING BAR BACKEND*/}
            { connectToBackend ? <BackendConnection/> : null}

            {/*USER INFORMATION*/}
            <Box className="user-content-field-wrapper">
                <Typography variant={"h3"}>
                    {t("account.accountSettings.title")}
                </Typography>
                <Box className="user-content-field">
                    {!isResetPassword ?
                        <>
                            {/*COMPANY NAME*/}
                            <TextField
                                label={t("common.companyName")}
                                value={company}
                                disabled={isVerified || (!isEditable && !isVerified)}
                                onChange={e => setCompany(e.target.value)}
                            />
                            {/*FIRST NAME*/}
                            <TextField
                                label={t("common.firstName")}
                                value={firstName}
                                disabled={!isEditable}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            {/*LAST NAME*/}
                            <TextField
                                label={t("common.lastName")}
                                value={lastName}
                                disabled={!isEditable}
                                onChange={e => setLastName(e.target.value)}
                            />
                            {/*PHONE NUMBER*/}
                            <MuiTelInput
                                label={t("common.phone")}
                                value={phoneNumber}
                                disabled={!isEditable}
                                defaultCountry="FR"
                                error={isPhoneNumberValid}
                                onChange={handlePhoneNumberChange}
                            />
                            {/*EMAIL*/}
                            <TextField
                                label={t("common.email")}
                                value={email}
                                disabled={!isEditable}
                                error={!isEmailValid}
                                onChange={e => setEmail(e.target.value)}
                            />
                            {/*PASSWORD*/}
                            <TextField
                                label={t("common.password")}
                                value={"••••••••••••"}
                                disabled={true}
                                type={'text'}
                            />
                        </>
                        :
                        <>
                            {/*NEW PASSWORD*/}
                            <TextField
                                label={t("common.newPassword")}
                                value={newPassword}
                                disabled={!isResetPassword}
                                type={showPassword ? 'text' : 'password'}
                                error={!isPasswordValid}
                                helperText={!isResetPassword ? null : "Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol."}
                                onChange={e => setNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {/*CONFIRM PASSWORD*/}
                            <TextField
                                label={t("common.passwordConfirmation")}
                                value={confirmNewPassword}
                                disabled={!isResetPassword}
                                type={showPassword ? 'text' : 'password'}
                                error={!isPasswordValid}
                                helperText={!isResetPassword ? null : "Password must be at least 12 characters long and must include a lowercase, uppercase, numeric and a symbol."}
                                onChange={e => setConfirmNewPassword(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            {/*CURRENT PASSWORD*/}
                            <TextField
                                label={t("common.currentPassword")}
                                value={currentPassword}
                                disabled={!isResetPassword}
                                type={showPassword ? 'text' : 'password'}
                                error={wrongCurrentPassword}
                                helperText={wrongCurrentPassword ? "Your password is incorrect." : null}
                                onChange={e => setCurrentPassword(e.target.value)}
                                InputProps={ isResetPassword ? {
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>  setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                } : <></> }
                            />
                        </>
                    }
                </Box>
            </Box>

            {/*CTA USER INFORMATION*/}
            <Box className="user-content-cta">
                {isEditable === false ?
                    <>
                        {isResetPassword ?
                            <>
                                <ButtonIcapeOutlined onClick={() => cancelResetPassword()}>
                                    {t("cta.cancel")}
                                </ButtonIcapeOutlined>
                                <ButtonIcape onClick={() => handlePasswordReset()}>
                                    {t("cta.reset")}
                                </ButtonIcape>
                            </>
                            :
                            <>
                                <ButtonIcapeOutlined onClick={() => setIsResetPassword(true)}>
                                    {t("cta.resetPassword")}
                                </ButtonIcapeOutlined>
                                <ButtonIcape onClick={() => handleEdit()}>
                                    {t("cta.edit")}
                                </ButtonIcape>
                            </>
                        }
                    </>
                    :
                    <>
                        <ButtonIcapeOutlined onClick={() => handleCancelEdit()}>
                            {t("cta.cancel")}
                        </ButtonIcapeOutlined>
                        <ButtonIcapeGreen onClick={handleUpdatedInfo}>
                            {t("cta.update")}
                        </ButtonIcapeGreen>
                    </>
                }
            </Box>

            {/*MAIL PREFERENCES*/}
            {isVerified &&
                <>
                    <Divider/>

                    <Box className="user-content-field-wrapper">

                        {/*TITLE*/}
                        <Typography variant={"h3"}>
                            {t("account.accountSettings.mailPreferences.title")}
                        </Typography>

                        {/*FIELDS*/}
                        <Box className="user-content-field-double-wrapper">

                            {/*TECHNICAL DOCUMENTS*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.technicalDocuments")}
                                </Typography>
                                <TextField
                                    label={t("common.email")}
                                    value={technicalDocsEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setTechnicalDocsEmail(e.target.value)}
                                />
                                <TextField
                                    label={t("account.accountSettings.mailPreferences.secondaryOptionalMail")}
                                    value={extraTechnicalDocsEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setExtraTechnicalDocsEmail(e.target.value)}
                                />
                            </Box>

                            {/*ORDER CONFIRMATION*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.orderConfirmation")}
                                </Typography>
                                <TextField
                                    label={t("common.email")}
                                    value={orderConfirmationEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setOrderConfirmationEmail(e.target.value)}
                                />
                                <TextField
                                    label={t("account.accountSettings.mailPreferences.secondaryOptionalMail")}
                                    value={extraOrderConfirmationEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setExtraOrderConfirmationEmail(e.target.value)}
                                />
                            </Box>

                            {/*DELIVERY NOTICE AND INVOICES*/}
                            <Box className="user-content-field-double">
                                <Typography component={"span"}>
                                    {t("account.accountSettings.mailPreferences.deliveryNotice")}
                                </Typography>
                                <TextField
                                    label={t("common.email")}
                                    value={deliveryAndInvoiceEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setDeliveryAndInvoiceEmail(e.target.value)}
                                />
                                <TextField
                                    label={t("account.accountSettings.mailPreferences.secondaryOptionalMail") }
                                    value={extraDeliveryAndInvoiceEmail}
                                    error={!isEmailValid}
                                    disabled={!isEditable}
                                    onChange={e => setExtraDeliveryAndInvoiceEmail(e.target.value)}
                                />
                            </Box>

                        </Box>
                    </Box>

                    {/*CTA*/}
                    <Box className="user-content-cta">
                        {isEditable === false ?
                            <ButtonIcape onClick={() => handleEdit()}>
                                {t("cta.edit")}
                            </ButtonIcape>
                            :
                            <>
                                <ButtonIcapeOutlined onClick={() => handleCancelEdit()}>
                                    {t("cta.cancel")}
                                </ButtonIcapeOutlined>
                                <ButtonIcapeGreen onClick={handleUpdatedInfo}>
                                    {t("cta.update")}
                                </ButtonIcapeGreen>
                            </>
                        }
                    </Box>
                </>
            }
        </>
    )
}

export default UserAccountSettings;
