import {createContext, useContext, useEffect, useState} from "react";
import {getStorageValue} from "../tools/cookie-handler";
import {mmToInch} from "../tools/unit.utils";
import {logInConsole} from "../tools/debug";
import UserContext from "./UserProvider";

const CalculatorHdiContext = createContext({});

export const CalculatorHdiProvider = ({ children }) => {
    const {defaultCountryFromAddresses, customerAddress, companyAddresses, availableCountries} = useContext(UserContext);


    // GLOBAL
    const [unitFormat, setUnitFormat] = useState(getStorageValue('hdi')?.unitFormat || "mm");
    const [partNumber, setPartNumber] = useState(getStorageValue('hdi')?.partNumber || "");
    const [isJaydaPcbQuotable, setIsJaydaPcbQuotable] = useState(true);
    const [connectToBO, setConnectToBO] = useState(false);
    const [errorBackendConnection, setErrorBackendConnection] = useState(false);
    const [repeatOrderMode, setRepeatOrderMode] = useState(getStorageValue('hdi')?.repeatOrder.repeatOrderMode || {background: false, enabled: false, lockPrice: false});
    const [repeatOrderCreationAt, setRepeatOrderCreationAt] = useState(getStorageValue('hdi')?.repeatOrder.repeatOrderCreationAt || '');
    const [isLockFields, setIsLockFields] = useState(getStorageValue('hdi')?.isLockFields || false);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [idOrder, setIdOrder] = useState(0);
    const [priceMatrix, setPriceMatrix] = useState({});

    const [customerReferences, setCustomerReferences] = useState([]);
    const [customerReferenceChoose, setCustomerReferenceChoose] = useState(
        {title: getStorageValue('hdi')?.partNumber || '', isNewReference: false}
    );
    const [repeatOrdersSelectedAvailable, setRepeatOrdersSelectedAvailable] = useState([]);
    const [isAvailableOrderSelected, setIsAvailableOrderSelected] = useState(false)

    const [productVariant, setProductVariant] = useState(getStorageValue('hdi')?.repeatOrder.repeatOrderMode.background ? getStorageValue('hdi')?.repeatOrder.productVariant : []);
    const [orderToken, setOrderToken] = useState("");
    const [userGerber, setUserGerber] = useState([]);
    const [userSpecFile, setUserSpecFile] = useState([]);
    const [pcbDeliveryFormat, setPcbDeliveryFormat] = useState(
        getStorageValue('hdi')?.pcbDeliveryFormat || 'single'
    );
    const [loadCurrency, setLoadCurrency] = useState(getStorageValue('rigid')?.loadCurrency || {});


    //JAYDA
    let jobId = null;
    const [ jobParams, setJobParams ] = useState({});
    const [ pcbImagesUrls, setPcbImagesUrls ] = useState({});
    const [ analyzeInProgress, setAnalyzeInProgress ] = useState('stop');
    const [ progress, setProgress ] = useState(0);
    const [ isErrorConnectToJayda, setIsErrorConnectToJayda ] = useState(false);
    const resetJayda = () => {
        setJobParams({});
        setPcbImagesUrls({});
        setAnalyzeInProgress('stop');
        setProgress(0);
        setIsErrorConnectToJayda(false);
    }


    //STEP 1
    const [isStepOneToFinalize, setIsStepOneToFinalize] = useState(false);

    const [pcbLength, setPcbLength] = useState(getStorageValue('hdi')?.pcb.pcbLength || "");
    const [pcbWidth, setPcbWidth] = useState(getStorageValue('hdi')?.pcb.pcbWidth || "");
    const [panelLength, setPanelLength] = useState(getStorageValue('hdi')?.pcb.panelLength || "");
    const [panelWidth, setPanelWidth] = useState(getStorageValue('hdi')?.pcb.panelWidth || "");

    const [quantity, setQuantity] = useState(getStorageValue('hdi')?.quantity || 10);
    const [quantityError, setQuantityError] = useState(false);
    const [panelQuantity, setPanelQuantity] = useState(getStorageValue('hdi')?.pcb.panelQuantity || 1);
    const [pcbPerPanel, setPcbPerPanel] = useState(getStorageValue('hdi')?.pcb.pcbPerPanel || 1);

    const [solderMaskId, setSolderMaskId] = useState(getStorageValue('hdi')?.pcb.solderMaskId || "");
    const [technologyId, setTechnologyId] = useState(getStorageValue('hdi')?.pcb.technologyId || "");
    const [xoutId, setXoutId] = useState(getStorageValue('hdi')?.pcb.xoutId || 1);

    // MM INCH
    const [pcbLengthIn, setPcbLengthIn] = useState(getStorageValue('hdi')?.pcb.pcbLengthIn || "");
    const [pcbWidthIn, setPcbWidthIn] = useState(getStorageValue('hdi')?.pcb.pcbWidthIn || "");
    const [panelLengthIn, setPanelLengthIn] = useState(getStorageValue('hdi')?.pcb.panelLengthIn || "");
    const [panelWidthIn, setPanelWidthIn] = useState(getStorageValue('hdi')?.pcb.panelWidthIn || "");


    // STEP 2
    //Technology
    const [finishedInnerCuId, setFinishedInnerCuId] = useState(getStorageValue('hdi')?.pcb.finishedInnerCuId || 1);
    const [finishedOuterCuId, setFinishedOuterCuId] = useState(getStorageValue('hdi')?.pcb.finishedOuterCuId || 1);
    const [ipc3Id, setIpc3Id] = useState(getStorageValue('hdi')?.pcb.ipc3Id || 1);
    const [resinFillingId, setResinFillingId] = useState(getStorageValue('hdi')?.pcb.resinFillingId || 1);
    const [copperFillingId, setCopperFillingId] = useState(getStorageValue('hdi')?.pcb.copperFillingId || 1);

    //Material
    const [rawMaterialId, setRawMaterialId] = useState(getStorageValue('hdi')?.pcb.rawMaterialId || 1);
    const [boardThicknessId, setBoardThicknessId] = useState(getStorageValue('hdi')?.pcb.boardThicknessId || 4);
    const [stepsNumberId, setstepsNumberId] = useState(getStorageValue('hdi')?.pcb.stepsNumberId || 1);

    //PCB Details
    const [solderMaskColorId, setSolderMaskColorId] = useState(getStorageValue('hdi')?.pcb.solderMaskColorId || 1);
    const [finishingKey, setFinishingKey] = useState(getStorageValue('hdi')?.pcb.finishingKey || 3);
    const [peelableMaskOptionId, setPeelableMaskOptionId] = useState(getStorageValue('hdi')?.pcb.peelableMaskOptionId || 1);

    //Advanced options
    const [impedanceControlId, setImpedanceControlId] = useState(getStorageValue('hdi')?.pcb.impedanceControlId || 1);
    const [pressFitHoleId, setpressFitHoleId] = useState(getStorageValue('hdi')?.pcb.pressFitHoleId || 1);
    const [counterSinkHoleId, setcounterSinkHoleId] = useState(getStorageValue('hdi')?.pcb.counterSinkHoleId || 1);
    const [laserDrillingId, setLaserDrillingId] = useState(getStorageValue('hdi')?.pcb.laserDrillingId || 1);
    const [buriedViaId, setBuriedViaId] = useState(getStorageValue('hdi')?.pcb.buriedViaId || 1);
    const [halfHoleId, sethalfHoleId] = useState(getStorageValue('hdi')?.pcb.halfHoleId || 1);
    const [blindViaId, setBlindViaId ] = useState(getStorageValue('hdi')?.pcb.blindViaId || 1);



    //STEP 3
    const [shippingMethodId, setShippingMethodId] = useState(getStorageValue('hdi')?.shipping.shippingMethodId || 2);
    const [country, setCountry] = useState(getStorageValue('rigid')?.shipping.country || {});
    const [totalLeadTime, setTotalLeadTime] = useState(getStorageValue('hdi')?.countryId || 1);
    const [isEconomyEligible, setIsEconomyEligible] = useState(false);


    const [requestedDate, setRequestedDate] = useState(getStorageValue('hdi')?.shipping.requestedDate || new Date());
    const [minDeliveryDate, setMinDeliveryDate] = useState(getStorageValue('hdi')?.shipping.minDeliveryDate || "");
    const [daysToDelivery, setDaysToDelivery] = useState(getStorageValue('hdi')?.shipping.daysToDelivery || "");
    const [productionTime, setProductionTime] = useState(getStorageValue('hdi')?.shipping.productionTime || "");
    const [persist, setPersist] = useState(false);
    const [comment, setComment] = useState(getStorageValue('hdi')?.comment || "");


    //PROJECT PANEL (aka _PP)
    //FROM API
    const [price_PP, setPrice_PP] = useState(0);
    const [shippingCost_PP, setShippingCost] = useState(getStorageValue('hdi')?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue('hdi')?.repeatOrder.prices.shipping : 0);
    const [supplierCost_PP, setSupplierCost_PP] = useState(0);
    const [cost_PP, setCost_PP] = useState(0);

    const [batchSize, setBatchSize] = useState(0)
    const [unitPrice_PP, setUnitPrice_PP] = useState(getStorageValue('hdi')?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue('hdi')?.repeatOrder.prices.unit : 0);
    const [stencil_PP, setStencil_PP] = useState(0);
    const [tariff, setTariff] = useState(0);
    const [total_PP, setTotal_PP] = useState(getStorageValue('hdi')?.repeatOrder.repeatOrderMode.lockPrice ? getStorageValue('hdi')?.repeatOrder.prices.total : 0);
    const [initialTotalPricePP, setInitialTotalPricePP] = useState(0);
    const [incoterm_PP, setIncoterm_PP] = useState("");

    const [voucher, setVoucher] = useState([]);
    const [weight, setWeight] = useState(0);


    useEffect(() => {
        if ( Object.keys(country).length === 0 && customerAddress.length > 0 && availableCountries.length > 0) {
            setCountry(defaultCountryFromAddresses);
        }
    }, [customerAddress, companyAddresses, availableCountries]);

    // FUNCTIONS
    /**
     * Filling data to place and order or a repeat order
     * @param refToLoad
     * @param {boolean} isRepeatOrder
     * @param {boolean} isCart
     */
    const loadCustomerReferenceHdi = (refToLoad, isRepeatOrder, isCart) => {
        resetAllHdi();

        if (isRepeatOrder) {
            logInConsole('Entering Rigid repeat order mode', 'gold');

            // Global
            setIdOrder(refToLoad.id);
            setRepeatOrderCreationAt(refToLoad.createdAt);
            setActiveStep(2);
        }

        //CURRENCY
        setLoadCurrency(refToLoad.currency);

        if (refToLoad.isValid && isCart) {
            logInConsole('Loading Rigid quote to order', 'gold');
            setProductVariant(refToLoad.productVariants);
            return

        } else if (isRepeatOrder || (refToLoad.isValid && !isCart)){

            setRepeatOrderMode({background: isRepeatOrder, enabled: true, lockPrice: true});
            setProductVariant(refToLoad.productVariants);

            // Pricing
            setShippingCost(refToLoad.prices.shippingCost);
            setBatchSize(refToLoad.batchSize);
            setUnitPrice_PP(refToLoad.prices.pcbUnitPrice);
            setStencil_PP(refToLoad.prices.stencilUnitPrice);
            setIncoterm_PP(refToLoad.incoterm);
            setTariff(refToLoad.prices.tariff);
            setInitialTotalPricePP(refToLoad.prices.fullPrice);
            setTotal_PP(refToLoad.prices.fullPrice);


        } else {
            console.log("passer par la matrice");
        }


        // Global
        setIsLockFields(true);
        setCompleted({0: true, 1: true});
        setPartNumber(refToLoad.pcbPartNumber);
        setCustomerReferenceChoose({title: refToLoad.pcbPartNumber, isNewReference: false});
        setQuantity(refToLoad.pcbQuantity);
        setPcbDeliveryFormat(refToLoad.deliveryFormat);
        setUnitFormat(refToLoad.unitFormat);

        // step 01
        setPcbLength(refToLoad.pcb.pcbLength);
        //TODO GET SIZE FROM BACKEND
        setPcbLengthIn(mmToInch(refToLoad.pcb.pcbLength));
        setPcbWidth(refToLoad.pcb.pcbWidth);
        //TODO GET SIZE FROM BACKEND
        setPcbWidthIn(mmToInch(refToLoad.pcb.pcbWidth));

        setPanelLength(refToLoad.panel.panelLength);
        setPanelLengthIn(refToLoad.panel.panelLengthIn);
        setPanelWidth(refToLoad.panel.panelWidth);
        setPanelWidthIn(refToLoad.panel.panelWidthIn);
        setPcbPerPanel(refToLoad.panel.pcbPerPanel);
        setPanelQuantity(refToLoad.panel.panelQuantity);

        setPcbDeliveryFormat(refToLoad.deliveryFormat);

        setSolderMaskId(refToLoad.pcb.solderMask);
        setTechnologyId(refToLoad.pcb.technology);
        setXoutId(refToLoad.pcb.xOut);

        // step 02
        setFinishedInnerCuId(refToLoad.pcb.innerCu);
        setFinishedOuterCuId(refToLoad.pcb.outerCu);
        setIpc3Id(refToLoad.pcb.IPC3);
        setResinFillingId(refToLoad.pcb.resinFilling);
        setCopperFillingId(refToLoad.pcb.copperFilling);

        setRawMaterialId(refToLoad.pcb.rawMaterial);
        setBoardThicknessId(refToLoad.pcb.boardThickness);
        setstepsNumberId(refToLoad.pcb.stepsNumber);

        setSolderMaskColorId(refToLoad.pcb.solderMaskColor);
        setFinishingKey(refToLoad.pcb.finishing);
        setPeelableMaskOptionId(refToLoad.pcb.peelableMaskOption);

        setImpedanceControlId(refToLoad.pcb.impedanceControl);
        setpressFitHoleId(refToLoad.pcb.pressFitHole);
        setcounterSinkHoleId(refToLoad.pcb.counterSinkHole);
        setLaserDrillingId(refToLoad.pcb.laserDrilling);
        setBuriedViaId(refToLoad.pcb.buriedVia);
        sethalfHoleId(refToLoad.pcb.halfHole);
        setBlindViaId(refToLoad.pcb.blindVia);


        // step 03
        setShippingMethodId(refToLoad.deliveryData.shippingMethodId);
        setCountry(refToLoad.deliveryData.country);
        setRequestedDate(refToLoad.deliveryData.estimatedDeliveryDate);
        setMinDeliveryDate(refToLoad.deliveryData.minDeliveryDate);
        setProductionTime(refToLoad.deliveryData.productionTime);
        setDaysToDelivery(refToLoad.deliveryData.daysToDelivery);

        setComment(refToLoad.comment);
    }

    /**
     * Reset all data from HDI calculator, including Jayda and local storage
     */
    const resetAllHdi = () => {
        logInConsole("Deleting all data HDI", "turquoise");

        //Global
        setCustomerReferenceChoose({title: '', isNewReference: false});
        setRepeatOrdersSelectedAvailable([]);
        setIsAvailableOrderSelected(false);
        setPartNumber('');
        setProductVariant({});
        setIsStepOneToFinalize(false);

        setIsJaydaPcbQuotable(true);
        setIsLockFields(false);
        setActiveStep(0);
        setCompleted({});
        setRepeatOrderMode({background: false, enabled: false, lockPrice: false});
        setRepeatOrderCreationAt('');
        setUserGerber([]);
        setUserSpecFile([]);
        setPcbDeliveryFormat('single');
        setLoadCurrency({});
        setQuantity(10);
        setPanelQuantity(1);
        resetJayda();

        //Project panel
        setPriceMatrix({});
        setUnitPrice_PP(0);
        setStencil_PP(0);
        setShippingCost(0);
        setTotal_PP(0);
        setInitialTotalPricePP(0);
        setIncoterm_PP('');
        setTariff(0);
        setBatchSize(0);

        localStorage.removeItem('hdi');
        localStorage.removeItem('designMyPanel');

        //Step1
        setPcbLength('');
        setPcbWidth('');
        setPanelLength('');
        setPanelWidth('');
        setPcbLengthIn('');
        setPcbWidthIn('');
        setPanelLengthIn('');
        setPanelWidthIn('');
        setPcbPerPanel(1);
        setSolderMaskId('');
        setTechnologyId('');
        setXoutId(1);

        //Step2
        setFinishedInnerCuId(1);
        setFinishedOuterCuId(1);
        setIpc3Id(1);
        setResinFillingId(1);
        setCopperFillingId(1);

        setRawMaterialId(1);
        setBoardThicknessId(4);
        setstepsNumberId(1);

        setSolderMaskColorId(1);
        setFinishingKey(3);
        setPeelableMaskOptionId(1);

        setImpedanceControlId(1);
        setpressFitHoleId(1);
        setcounterSinkHoleId(1);
        setLaserDrillingId(1);
        setBuriedViaId(1);
        sethalfHoleId(1);
        setBlindViaId(1);

        //Step3
        setShippingMethodId(2);
        setCountry(defaultCountryFromAddresses);
        setProductionTime("");
        setDaysToDelivery("");

        setComment("");
    }

    return (
        <CalculatorHdiContext.Provider value={{
            //GLOBAL
            idOrder, setIdOrder,
            priceMatrix, setPriceMatrix,
            partNumber, setPartNumber,
            repeatOrderMode, setRepeatOrderMode,
            repeatOrderCreationAt, setRepeatOrderCreationAt,
            activeStep, setActiveStep,
            completed, setCompleted,
            isLockFields, setIsLockFields,
            pcbDeliveryFormat, setPcbDeliveryFormat,
            isStepOneToFinalize, setIsStepOneToFinalize,
            quantity, setQuantity,
            panelQuantity, setPanelQuantity,
            quantityError, setQuantityError,
            customerReferences, setCustomerReferences,
            customerReferenceChoose, setCustomerReferenceChoose,
            repeatOrdersSelectedAvailable, setRepeatOrdersSelectedAvailable,
            isAvailableOrderSelected, setIsAvailableOrderSelected,
            productVariant, setProductVariant,
            orderToken, setOrderToken,
            unitFormat, setUnitFormat,
            connectToBO, setConnectToBO,
            errorBackendConnection, setErrorBackendConnection,
            loadCurrency, setLoadCurrency,

            //PROJECT PANEL
            price_PP, setPrice_PP,
            shippingCost_PP, setShippingCost,
            supplierCost_PP, setSupplierCost_PP,
            cost_PP, setCost_PP,
            unitPrice_PP, setUnitPrice_PP,
            stencil_PP, setStencil_PP,
            total_PP, setTotal_PP,
            initialTotalPricePP, setInitialTotalPricePP,
            incoterm_PP, setIncoterm_PP,
            voucher, setVoucher,
            weight, setWeight,
            tariff, setTariff,
            batchSize, setBatchSize,

            //JAYDA
            isJaydaPcbQuotable, setIsJaydaPcbQuotable,
            userGerber, setUserGerber,
            userSpecFile, setUserSpecFile,
            jobId,
            jobParams, setJobParams,
            pcbImagesUrls, setPcbImagesUrls,
            analyzeInProgress, setAnalyzeInProgress,
            progress, setProgress,
            isErrorConnectToJayda, setIsErrorConnectToJayda,

            //STEP 01
            pcbLength, setPcbLength,
            pcbWidth, setPcbWidth,
            pcbLengthIn, setPcbLengthIn,
            pcbWidthIn, setPcbWidthIn,
            panelLength, setPanelLength,
            panelWidth, setPanelWidth,
            panelLengthIn, setPanelLengthIn,
            panelWidthIn, setPanelWidthIn,
            pcbPerPanel, setPcbPerPanel,
            solderMaskId, setSolderMaskId,
            technologyId, setTechnologyId,
            xoutId, setXoutId,

            //STEP 02
            finishedInnerCuId, setFinishedInnerCuId,
            finishedOuterCuId, setFinishedOuterCuId,
            ipc3Id, setIpc3Id,
            resinFillingId, setResinFillingId,
            copperFillingId, setCopperFillingId,
            rawMaterialId, setRawMaterialId,
            boardThicknessId, setBoardThicknessId,
            stepsNumberId, setstepsNumberId,
            solderMaskColorId, setSolderMaskColorId,
            finishingKey, setFinishingKey,
            peelableMaskOptionId, setPeelableMaskOptionId,
            impedanceControlId, setImpedanceControlId,
            pressFitHoleId, setpressFitHoleId,
            counterSinkHoleId, setcounterSinkHoleId,
            laserDrillingId, setLaserDrillingId,
            buriedViaId, setBuriedViaId,
            halfHoleId, sethalfHoleId,
            blindViaId, setBlindViaId,

            //STEP 03
            shippingMethodId, setShippingMethodId,
            country, setCountry,
            requestedDate, setRequestedDate,
            minDeliveryDate, setMinDeliveryDate,
            daysToDelivery, setDaysToDelivery,
            productionTime, setProductionTime,
            persist, setPersist,
            totalLeadTime, setTotalLeadTime,
            isEconomyEligible, setIsEconomyEligible,
            comment, setComment,

            //FUNCTIONS
            loadCustomerReferenceHdi,
            resetAllHdi
        }} displayName="CalculatorHdi">
            {children}
        </CalculatorHdiContext.Provider>
    )
}

export default CalculatorHdiContext;
