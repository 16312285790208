import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import UserAddressList from "./user-address-list";
import {ButtonIcapeOutlinedRed, ButtonIcapeSoftBlue} from "../../../button/button-icape";
import React, {useContext, useState} from "react";
import UserAddressEdit from "./user-address-edit";
import "./user-addresses.scss"
import {CUSTOMER_ADDRESS} from "../../../../services/customer.service";
import {axiosPrivate} from "../../../../api/axios";
import BackendConnection from "../../../backend-connection/backend-connection";
import {logInConsole} from "../../../../tools/debug";
import {useTranslation} from "react-i18next";
import UserContext from "../../../../context/UserProvider";
import {ErrorHandling} from "../../../../tools/error-handling";

const UserAddresses = () => {
    const {t} = useTranslation();

    const {
        auth,
        connectToBackend, setConnectToBackend,
        setErrorBackendConnection,
        isVerified,
        countriesList,
        customerAddress,
        companyId,
        companyAddresses,
        addressId, setAddressId,
        getAddress, getCompanyAddress,
        aliasAddr, setAliasAddr,
        firstNameAddr, setFirstNameAddr,
        lastNameAddr, setLastNameAddr,
        companyAddr, setCompanyAddr,
        phoneNumberAddr, setPhoneNumberAddr,
        street, setStreet,
        postcode, setPostcode,
        city, setCity,
        countryCode, setCountryCode,
        isDefaultAddress, setIsDefaultAddress,
        isPrivateAddress, setIsPrivateAddress,
        syncAddrFromDB, syncCompanyAddressesFromDB
    } = useContext(UserContext);

    const [showFormAddress, setShowFormAddress] = useState(false);

    const [isAddAddress, setIsAddAddress] = useState(false);
    const [isDeletingPopup, setIsDeletingPopup] = useState(false);
    const [isCompanyAddress, setIsCompanyAddress] = useState(false);

    const handleModifyAddress = (id, isCompanyAddress) => {
        logInConsole(`Modifying address ${id}`, 'yellow');
        setAddressId(id);
        setIsAddAddress(false);
        setShowFormAddress(true);
        setIsCompanyAddress(isCompanyAddress);


        //SET VARIABLES CHAMPS
        // setCompanyAddr(isCompanyAddress ? getCompanyAddress(id)?.company : getAddress(id)?.company);
        setAliasAddr(isCompanyAddress ? getCompanyAddress(id)?.alias : getAddress(id)?.alias);
        setFirstNameAddr(isCompanyAddress ? getCompanyAddress(id)?.firstName : getAddress(id)?.firstName);
        setLastNameAddr(isCompanyAddress ? getCompanyAddress(id)?.lastName : getAddress(id)?.lastName);
        setPhoneNumberAddr(isCompanyAddress ? getCompanyAddress(id)?.phoneNumber : getAddress(id)?.phoneNumber);
        setStreet(isCompanyAddress ? getCompanyAddress(id)?.street : getAddress(id)?.street);
        setPostcode(isCompanyAddress ? getCompanyAddress(id)?.postcode : getAddress(id)?.postcode);
        setCity(isCompanyAddress ? getCompanyAddress(id)?.city : getAddress(id)?.city);
        setCountryCode(isCompanyAddress ? getCompanyAddress(id)?.countryCode : getAddress(id)?.countryCode);
        setIsDefaultAddress(isCompanyAddress ? getCompanyAddress(id)?.isDefaultAddress : getAddress(id)?.isDefaultAddress);
        isCompanyAddress ? setIsPrivateAddress(false) : setIsPrivateAddress(getAddress(id)?.isPrivateAddress);

        window.scrollTo(0,0);
    }
    const handleDeleteAddress = (id) => {
        setConnectToBackend(true)
        logInConsole(`Deleting address ${id}`, 'yellow');
        setAddressId(id);

        //DELETE ACTION
        axiosPrivate.delete(`${process.env.REACT_APP_BACKEND_API_BASE_URL}` + CUSTOMER_ADDRESS + '/' + id)
            .then(
                (response) => {
                    logInConsole(`Address ${id} deleted`, 'green');
                    syncAddrFromDB();
                    setIsDeletingPopup(0);
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString();
                    logInConsole(`Deleting address ${id} failed`, 'red');
                    logInConsole(`--->  ${resMessage}`, 'red');
                    setErrorBackendConnection(true);
                    ErrorHandling(null, resMessage);
                }
            ).finally(
            () => {
                setConnectToBackend(false)
            }
        )
    }
    const handleAddAddress = () => {

        // RESET DES CHAMPS
        setCompanyAddr('');
        setAliasAddr('');

        setFirstNameAddr('');
        setLastNameAddr('');
        setPhoneNumberAddr('');
        setStreet('');
        setPostcode('');
        setCity('');
        setCountryCode('');
        setIsDefaultAddress(false);
        setIsPrivateAddress(false);

        setShowFormAddress(true);
        setIsAddAddress(true);
        logInConsole(`User is adding an address`, 'yellow');
    }
    const handleAddData = () => {
        setConnectToBackend(true);
        logInConsole(`Adding a new address`, 'yellow');
        if(isAddAddress === true) {
            let config = {headers: {"Content-Type": "application/json"}};
            let data = {
                alias : aliasAddr,
                firstName : firstNameAddr,
                lastName : lastNameAddr,
                company : companyAddr,
                phoneNumber: phoneNumberAddr,
                countryCode,
                street,
                city,
                postcode,
                isDefaultAddress,
                isPrivateAddress
            };

            //POST(ADD) ACTION
            axiosPrivate.post(`${process.env.REACT_APP_BACKEND_API_BASE_URL}` + CUSTOMER_ADDRESS + '/' + auth.customerId + '/customized', data, config)
                .then(
                    (response) => {
                        logInConsole(`Address successfully created`, 'green');
                        syncAddrFromDB();
                        setShowFormAddress(false);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        logInConsole(`Address creation failed`, 'red');
                        logInConsole(`---> ${resMessage}`, 'red');
                        setErrorBackendConnection(true);
                        ErrorHandling(null, resMessage);
                        setShowFormAddress(false);
                    }
                ).finally(
                () => {
                    setConnectToBackend(false);
                }
            )
        }
    }
    const handleUpdateAddress = (id, isCompanyAddress) => {
        setConnectToBackend(true)
        logInConsole(`Updating address ${id.id}`, 'yellow');
        isCompanyAddress && logInConsole(`This is a company address`, 'yellow');
        setAddressId(id);

        let config = {headers: {"Content-Type": "application/json"}};


        if (isCompanyAddress) {
            let dataCompany = {
                companyId,
                alias : aliasAddr,
                firstName : firstNameAddr,
                lastName : lastNameAddr,
                company : companyAddr,
                phoneNumber: phoneNumberAddr,
                countryCode,
                street,
                city,
                postcode,
                isDefaultAddress,
                isPrivateAddress : false
            };

            //PUT (UPDATE) ACTION
            let putUrlCompany = `${process.env.REACT_APP_BACKEND_API_BASE_URL}` + CUSTOMER_ADDRESS + '/' + companyId + '/ID/customized/'.replace('ID', auth.customerId) + id.id;
            axiosPrivate.put(putUrlCompany, dataCompany, config)
                .then(
                    (response) => {
                        logInConsole(`Company address ${id.id} updated`, 'green');
                        syncCompanyAddressesFromDB(companyId);
                        setShowFormAddress(false);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        logInConsole(`Updating company address ${id.id} failed`, 'red');
                        logInConsole(`--->  ${resMessage}`, 'red');
                        setErrorBackendConnection(true);
                        ErrorHandling(null, resMessage);
                        setShowFormAddress(false);
                    }
                ).finally(
                () => {
                    setConnectToBackend(false);
                }
            )
        } else {
            let data = {
                // company : companyAddr,
                alias : aliasAddr,
                firstName : firstNameAddr,
                lastName : lastNameAddr,
                phoneNumber: phoneNumberAddr,
                countryCode,
                street,
                city,
                postcode,
                isDefaultAddress,
                isPrivateAddress
            };

            //PUT (UPDATE) ACTION
            let putUrl = `${process.env.REACT_APP_BACKEND_API_BASE_URL}` + CUSTOMER_ADDRESS + '/ID/customized/'.replace('ID', auth.customerId) + id.id;
            axiosPrivate.put(putUrl, data, config)
                .then(
                    (response) => {
                        logInConsole(`Address ${id.id} updated`, 'green');
                        syncAddrFromDB();
                        setShowFormAddress(false);
                    },
                    (error) => {
                        const resMessage = (error.response && error.response.data && error.response.data.message) ||
                            error.message || error.toString();
                        logInConsole(`Updating address ${id.id} failed`, 'red');
                        logInConsole(`--->  ${resMessage}`, 'red');
                        setErrorBackendConnection(true);
                        ErrorHandling(null, resMessage);
                        setShowFormAddress(false);
                    }
                ).finally(
                () => {
                    setConnectToBackend(false);
                }
            )
        }
    }

    return (
        <>
            {/*LOADING BAR BACKEND*/}
            { connectToBackend ? <BackendConnection/> : null}

            <Box className="user-content-field-wrapper">

                {/*TITLE*/}
                {!showFormAddress ?
                    <Typography variant={"h3"}>
                        {t("account.addresses.title")}
                    </Typography>
                    :
                    <Typography variant={"h3"}>
                        Address edition
                    </Typography>
                }



                {/*ADRESSES*/}
                {
                    showFormAddress ?
                        <UserAddressEdit
                            address={isCompanyAddress ? getCompanyAddress(addressId): getAddress(addressId)}
                            handleUpdateAddress={handleUpdateAddress}
                            isAddAddress={isAddAddress}
                            isCompanyAddress={isCompanyAddress}
                            handleAddAddress={handleAddAddress}
                            handleAddData={handleAddData}

                            // company={companyAddr}
                            alias={aliasAddr}
                            firstName={firstNameAddr}
                            lastName={lastNameAddr}
                            phoneNumber={phoneNumberAddr}
                            postcode={postcode}
                            street={street}
                            city={city}
                            countryCode={countryCode}
                            countriesList={countriesList}
                            isDefaultAddress={isDefaultAddress}
                            isPrivateAddress={isPrivateAddress}

                            // setCompany={setCompanyAddr}
                            setAlias={setAliasAddr}
                            setFirstName={setFirstNameAddr}
                            setLastName={setLastNameAddr}
                            setPhoneNumber={setPhoneNumberAddr}
                            setPostcode={setPostcode}
                            setStreet={setStreet}
                            setCity={setCity}
                            setCountryCode={setCountryCode}
                            setIsDefaultAddress={setIsDefaultAddress}
                            setIsPrivateAddress={setIsPrivateAddress}
                            setShowFormAddress={setShowFormAddress}
                        />
                        :
                        <>
                            {/*USER ADDRESSES*/}
                            <Box className="user-addresses-wrapper">
                                {
                                    customerAddress.map(
                                        (address) => <UserAddressList
                                            key={address.id}
                                            address={address}
                                            handleModifyAddress={handleModifyAddress}
                                            setIsDeletingPopup={setIsDeletingPopup}
                                            countriesList={countriesList}
                                            isCompanyAddress={false}
                                        />
                                    )
                                }

                                {/*CTA ADD ADDRESS*/}
                                {customerAddress.length <= 5 ?
                                    <Box className="user-address-card add-address-cta" onClick={handleAddAddress}>
                                        +
                                        <span>Add an address</span>
                                    </Box>
                                    : null
                                }
                            </Box>

                            {/*COMPANY ADDRESSES*/}
                            {isVerified &&
                                <>
                                    <Typography variant={"h3"} sx={{marginTop: "30px"}}>
                                        {t("account.addresses.secondTitle")}
                                    </Typography>

                                    <Box className="user-addresses-wrapper">
                                        {
                                            companyAddresses.map(
                                                (companyAddress) => <UserAddressList
                                                    key={companyAddress.id}
                                                    address={companyAddress}
                                                    handleModifyAddress={handleModifyAddress}
                                                    setIsDeletingPopup={setIsDeletingPopup}
                                                    countriesList={countriesList}
                                                    isCompanyAddress={true}
                                                />
                                            )
                                        }
                                    </Box>
                                </>
                            }

                            {/*DELETE POPUP*/}
                            {isDeletingPopup !== 0 ?
                                <>
                                    <Dialog open={isDeletingPopup} onClose={ () => setIsDeletingPopup(false)}>
                                        <DialogTitle>
                                            {t("popup.deleteAddress.title")}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {t("popup.deleteAddress.content")}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <ButtonIcapeOutlinedRed onClick={ () => handleDeleteAddress(isDeletingPopup)}>
                                                {t("cta.delete")}
                                            </ButtonIcapeOutlinedRed>
                                            <ButtonIcapeSoftBlue onClick={ () => setIsDeletingPopup(0)}>
                                                {t("cta.cancel")}
                                            </ButtonIcapeSoftBlue>
                                        </DialogActions>
                                    </Dialog>
                                </>
                                : <></>
                            }
                        </>

                }
            </Box>
        </>
    )
}

export default UserAddresses;
