import {axios, axiosPrivate, BASE_URL} from '../api/axios';
import authHeader from "./auth-header";

const COUNTRIES_LIST = "/shop/countries?itemsPerPage=244";

const getCountries = async (postUrl, customerId) => {
    postUrl = postUrl.replace('ID', customerId)
    // console.log("post url : ", postUrl)
    return axiosPrivate.get(BASE_URL + postUrl, {headers: authHeader()})
        .then(
            (response) => {
                // console.log("[countries.service] RESPONSE : ", response.data)
                return response.data;
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                console.log("---> ", resMessage);
                throw error
            }
        )
}


export {
    getCountries,
    COUNTRIES_LIST
};
