import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import "./pcbBox.scss";

export default function PcbBox(props) {

    const [isShowingTopMilling, setIsShowingTopMilling] = useState(true);
    const [isShowingBottomMilling, setIsShowingBottomMilling] = useState(true);
    const [isShowingLeftMilling, setIsShowingLeftMilling] = useState(true);
    const [isShowingRightMilling, setIsShowingRightMilling] = useState(true);

    useEffect(() => {

        //TOP
        if (props.isFirstY) {
            setIsShowingTopMilling(props.isTechnicalBorderTop)
        } else {
            setIsShowingTopMilling(true)
        }
        //LEFT
        if (props.isFirstX) {
            setIsShowingLeftMilling(props.isTechnicalBorderLeft)
        } else {
            setIsShowingLeftMilling(true)
        }
        //RIGHT
        if (props.isLastX) {
            setIsShowingRightMilling(props.isTechnicalBorderRight)
        } else {
            setIsShowingRightMilling(props.isSpaceX)
        }
        //BOTTOM
        if (props.isLastY) {
            setIsShowingBottomMilling(props.isTechnicalBorderBottom)
        } else {
            setIsShowingBottomMilling(props.isSpaceY)
        }

    }, [props.isTechnicalBorderTop, props.isTechnicalBorderBottom, props.isTechnicalBorderLeft, props.isTechnicalBorderRight, props.isFirstY, props.isLastY, props.isSpaceY, props.isFirstX, props.isLastX, props.isSpaceX])

    return (
        <div>
            {(props.separationY === 3 && isShowingTopMilling) && (
                <Box className="milling-internal-whole"
                     sx={{
                         width: "100%",
                         height: "4px",
                         borderLeft: `${(props.width * 15) / 100}px solid #007dc8`,
                         borderRight: `${(props.width * 15) / 100}px solid #007dc8`
                     }}
                ></Box>
            )}
            <div style={{display: "flex"}}>
                {(props.separationX === 3 && isShowingLeftMilling) && (
                    <Box className="milling-internal-whole"
                         sx={{
                             width: "4px",
                             borderTop: `${(props.height * 15) / 100}px solid #007dc8`,
                             borderBottom: `${(props.height * 15) / 100}px solid #007dc8`
                         }}
                    ></Box>
                )}
                <Box className="pcbBox"
                     sx={{height: props.height, width: props.width}}
                ></Box>
                {(props.separationX === 3 && isShowingRightMilling) && (
                    <Box className="milling-internal-whole"
                         sx={{
                             width: "4px",
                             borderTop: `${(props.height * 15) / 100}px solid #007dc8`,
                             borderBottom: `${(props.height * 15) / 100}px solid #007dc8`
                         }}
                    ></Box>
                )}
            </div>
            {(props.separationY === 3 && isShowingBottomMilling) && (
                <Box className="milling-internal-whole"
                     sx={{
                         width: "100%",
                         height: "4px",
                         borderLeft: `${(props.width * 15) / 100}px solid #007dc8`,
                         borderRight: `${(props.width * 15) / 100}px solid #007dc8`
                     }}
                ></Box>
            )}
        </div>
    );
}
